import { configureStore } from "@reduxjs/toolkit";
import { api } from "../Features/api/api";
import studentReducer from "../Features/properties/StudentService";

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        students: studentReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
});
