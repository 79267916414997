import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GetSingleStudentData } from "../Features/properties/StudentService";
import moment from "moment";
import Loader from "../Components/Loader";
import Cookies from "js-cookie";

const Receipt = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getSingleStudentData, loading } = useSelector(
    (state) => state.students
  );
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [paymentId, setPaymentId] = useState("");
  const [studId, setStudId] = useState("");

  useEffect(() => {
    const accessToken = Cookies.get("scholar-auth-token");
    if (!accessToken) {
      navigate("/adminpanel");
    }
  }, [navigate]);

  useEffect(() => {
    setPaymentId(searchParams.get("paymentid"));
    setStudId(searchParams.get("studid"));
  }, [paymentId, studId, searchParams]);

  useEffect(() => {
    if (studId) {
      dispatch(GetSingleStudentData(studId));
    }
  }, [paymentId, studId, dispatch]);

  const handleButtonClick = () => {
    window.print();
  };

  const handleBackButtonClick = () => {
    navigate("/adminpanel/fees");
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <div className="text-center">
        <button
          className="btn btn-thmgreen mx-3 my-2"
          onClick={handleButtonClick}
        >
          Print
        </button>
        <button className="btn btn-thmdanger" onClick={handleBackButtonClick}>
          Back
        </button>
      </div>
      <div className="border border-5 p-5 ">
        <div className="mb-5 d-flex justify-content-between">
          <img
            src="https://shreescholarstarnursery.in/adminpanel/static/media/logo.png"
            alt="school logo"
            width="110"
            height="110"
          />
          <div className="mt-3 text-center text-justify">
            <h3>
              <u>SHREE SCHOLAR STAR NURSERY</u>
            </h3>
            <h6>
              139/Golden Square 5th Floor, Beside D-Mart, Mota Varachha, <br />
              Surat, Gujarat 394101
            </h6>
          </div>
        </div>
        <hr />
        <div className="text-center mt-3">
          <h4>
            <u>Fees Receipt</u>
          </h4>
        </div>
        <div className="d-flex justify-content-between mt-5">
          <div>
            <p>
              <b>Student Name: </b>
              {getSingleStudentData?.studentname}
            </p>
            <p>
              <b>Standard: </b>
              {getSingleStudentData?.standard}
            </p>
            <p>
              <b>Medium: </b>
              {getSingleStudentData?.medium}
            </p>
            <p>
              <b>GR No: </b>
              {getSingleStudentData?.gr}
            </p>
          </div>
          <div className="text-justify">
            <p>
              <b>Receipt No: </b>
              {getSingleStudentData?.payment_details.find(payment => payment._id === paymentId)?.receiptno}
            </p>
            <p>
              <b>Date: </b>
              {moment(
                getSingleStudentData?.payment_details.find(payment => payment._id === paymentId)?.created
              ).format("DD/MM/YYYY")}
            </p>
          </div>
        </div>
        <hr />
        <div className="height">
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-default">
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="text-center">
                        <tr>
                          <th width="5%">No</th>
                          <th className="text-left">DESCRIPTION</th>
                          <th width="10%" className="text-right">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        <tr>
                          <td>1</td>
                          <td className="text-left">School Fees</td>
                          <td className="total">
                            {
                              getSingleStudentData?.payment_details.find(payment => payment._id === paymentId)?.payingfees
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td className="text-left"> -</td>
                          <td className="total">
                            -
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td className="text-left">-</td>
                          <td className="total">
                            -
                          </td>
                        </tr>
                      </tbody>

                      <tfoot>
                        <tr>
                          <td colspan="2" className="text-end">
                            TOTAL
                          </td>
                          <td className="text-center">
                            {
                              getSingleStudentData?.payment_details.find(payment => payment._id === paymentId)?.payingfees
                            }
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-5">
          <div>
            <div>
              <img
                src="https://shreescholarstarnursery.in/adminpanel/static/media/stamp.png"
                className="center"
                alt="school logo"
                width="80"
                height="80"
              />
            </div>
            <p className="stamp-label">
              <b>Stamp</b>
            </p>
          </div>
          <div>
            <div>
              <img
                src="https://shreescholarstarnursery.in/adminpanel/static/media/digitalsignature.png"
                className="center"
                alt="school logo"
                width="170"
                height="80"
              />
            </div>
            <p className="signature-label">
              <b>Signature</b>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Receipt;
