import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import {
  AddTopic,
  GetSingleTopicData,
  GetTopicList,
  UpdateTpoic,
  removeTopicRecord,
} from "../../Features/properties/StudentService";

const AddSyllabus = ({ setOpenModal, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAllStandardMedium, getSingleTopicData, loading } = useSelector(
    (state) => state.students
  );

  useEffect(() => {
    if (id) {
      dispatch(GetSingleTopicData(id));
    } else {
      dispatch(removeTopicRecord());
    }
  }, [id, dispatch]);

  const validate = (values) => {
    const errors = {};

    if (!values.standard) {
      errors.standard = "This field is required";
    }
    if (!values.medium) {
      errors.medium = "This field is required";
    }
    if (!values.title) {
      errors.title = "This field is required";
    }
    if (!values.topic) {
      errors.topic = "This field is required";
    }

    return errors;
  };
  return (
    <>
      <div className="col-lg-12">
        {loading ? <Loader /> : <></>}
        <div className="my_dashboard_review">
          <div className="row mb20">
            <Formik
              initialValues={{
                standard: id ? getSingleTopicData?.standard : "" || "",
                medium: id ? getSingleTopicData?.medium : "" || "",
                title: id ? getSingleTopicData?.title : "" || "",
                topic: id ? getSingleTopicData?.topic : "" || "",
              }}
              validate={validate}
              enableReinitialize={true}
              onSubmit={async (values) => {
                let data = { ...values };
                let res;
                if (id) {
                  res = await dispatch(UpdateTpoic({ id: id, data: data }));
                } else {
                  res = await dispatch(AddTopic(data));
                }

                if (res?.type === "students/AddTopic/fulfilled") {
                  toast.success("Topic Inserted Successfully", {
                    autoClose: 3000,
                  });
                  await dispatch(GetTopicList());
                  setOpenModal(false);
                } else if (res?.type === "students/UpdateTpoic/fulfilled") {
                  toast.success("Topic Updated Successfully", {
                    autoClose: 3000,
                  });
                  await dispatch(GetTopicList());
                  return navigate("/adminpanel/syllabus");
                } else {
                  toast.error("Something Went Wrong", {
                    autoClose: 3000,
                  });
                }
              }}
            >
              {({ values, errors, touched, setFieldValue, handleChange }) => (
                <Form>
                  <div className="row p-2">
                    <div data-mdb-input-init className="form-outline mb-4 col">
                      <label className="form-label" htmlFor="std">
                        Select standard
                        <span className="error">*</span>
                      </label>
                      <select
                        className="form-select"
                        name="standard"
                        id="standard"
                        onChange={handleChange}
                        value={values?.standard || ""}
                        aria-label="Default select example"
                      >
                        <option value="">Select Standard</option>
                        {getAllStandardMedium &&
                            getAllStandardMedium.standard &&
                            getAllStandardMedium.standard.map((std, index) => (
                              <option value={std} key={index}>
                                {std}
                              </option>
                            ))}
                      </select>
                      {touched?.standard && errors?.standard ? (
                        <div className="error">{errors?.standard}</div>
                      ) : null}
                    </div>
                    <div data-mdb-input-init className="form-outline mb-4 col">
                      <label
                        className="form-label"
                        htmlFor="medium"
                        id="medium"
                      >
                        Select medium
                        <span className="error">*</span>
                      </label>
                      <select
                        className="form-select"
                        placeholder="Select medium"
                        name="medium"
                        aria-label="Default select example"
                        onChange={handleChange}
                        value={values?.medium || ""}
                      >
                        <option value="">Select Medium</option>
                        {getAllStandardMedium &&
                            getAllStandardMedium.medium &&
                            getAllStandardMedium.medium.map((med, index) => (
                              <option value={med} key={index}>
                                {med}
                              </option>
                            ))}
                      </select>
                      {touched?.medium && errors?.medium ? (
                        <div className="error">{errors?.medium}</div>
                      ) : null}
                    </div>

                    <div className="form-outline col flex-fill">
                      <label className="form-label" htmlFor="title">
                        Title
                        <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        id="title"
                        onChange={handleChange}
                        value={values?.title}
                      />
                      {touched?.title && errors?.title ? (
                        <div className="error">{errors?.title}</div>
                      ) : null}
                    </div>
                    <div className="form-outline col flex-fill">
                      <label className="form-label" htmlFor="topic">
                        Topic
                        <span className="error">*</span>
                      </label>

                      <textarea
                        name="topic"
                        id="topic"
                        className="form-control"
                        cols="30"
                        rows="3"
                        onChange={handleChange}
                        value={values?.topic}
                      ></textarea>
                      {touched?.topic && errors?.topic ? (
                        <div className="error">{errors?.topic}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    {id ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-thmdanger mx-2"
                          onClick={() => navigate("/adminpanel/syllabus")}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-thmdanger mx-2"
                          onClick={() => setOpenModal(false)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                    {id ? (
                      <>
                        <input
                          type="submit"
                          id="addstudent"
                          name="submit"
                          value="Update Topic"
                          className="btn btn-thm"
                        />
                      </>
                    ) : (
                      <>
                        <input
                          type="submit"
                          id="addstudent"
                          name="submit"
                          value="Add Topic"
                          className="btn btn-thm"
                        />
                      </>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSyllabus;
