import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AddTeacherRecord, GetAllTeachersList, GetSingleTeacherData, UpdateTeacher, removeTeacherRecord } from '../../Features/properties/StudentService';
import Loader from '../Loader';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import SingleImageUploder from '../SingleImageUploder';
import moment from 'moment';



const AddTeacher = ({ setOpenModal, id }) => {
    const dispatch = useDispatch();
  const navigate = useNavigate();
  const [aadharcardimage, setAadharcardimage] = useState([]);
  const { getSingleTeacherData, loading } = useSelector(
    (state) => state.students
  );

  const validate = (values) => {
    const errors = {};
  
    if (!values.teachername) {
      errors.teachername = "This field is required";
    }
    if (!values.gender) {
      errors.gender = "This field is required";
    }
    if (!values.address) {
      errors.address = "This field is required";
    }
    if (!values.mobileno) {
      errors.mobileno = "This field is required";
    }else if(String(values.mobileno).length !== 10){
      errors.mobileno = "Must be 10 Characters";
    }
    if (!values.dateofbirth) {
      errors.dateofbirth = "This field is required";
    }
    if (!values.email) {
      errors.email = "This field is required";
    }
    // if (!id) {
    //   if (!aadharcardimage.length) {
    //     errors.aadharcardimage = "AadharCard Image is Require";
    //   }
    // }
  
    return errors;
  };

  useEffect(() => {
    if (id) {
      dispatch(GetSingleTeacherData(id));
    } else {
      dispatch(removeTeacherRecord());
    }
  }, [id, dispatch]);

  // Utility function to convert date format
  const convertDateFormat = (date) => {
    return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
  };

  const convertDate = (date) => {
    return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
  };

  return (
    <>
      <div className="col-lg-12">
        {loading ? <Loader /> : <></>}
        <div className="my_dashboard_review">
          <div className="row mb20">
            <Formik
              initialValues={{
                teachername: id ? getSingleTeacherData?.teachername : "" || "",
                gender: id ? getSingleTeacherData?.gender : "male" || "",
                address: id ? getSingleTeacherData?.address : "" || "",
                mobileno: id ? getSingleTeacherData?.mobileno : "" || "",
                email: id ? getSingleTeacherData?.email : "" || "",
                dateofbirth: id ? convertDateFormat(getSingleTeacherData?.dateofbirth) : "" || "",
                bankname: id ? getSingleTeacherData?.bankdetails?.bankname : "" || "",
                accountnumber: id ? getSingleTeacherData?.bankdetails?.accountnumber : "" || "",
                accounttype: id ? getSingleTeacherData?.bankdetails?.accounttype : "" || "",
                branchname: id ? getSingleTeacherData?.bankdetails?.branchname : "" || "",
                branchaddress: id ? getSingleTeacherData?.bankdetails?.branchaddress : "" || "",
                IFSCcode: id ? getSingleTeacherData?.bankdetails?.IFSCcode : "" || "",
              }}
              validate={validate}
              enableReinitialize={true}
              onSubmit={async (values) => {
                let data = {};
                if (id) {
                  data = {
                    ...values,
                    dateofbirth: convertDate(values?.dateofbirth),
                    teacheradharcard: aadharcardimage.length ? aadharcardimage[0]?.file : "",
                    teacher_old_adharcard: getSingleTeacherData?.teacheradharcard ? getSingleTeacherData?.teacheradharcard : "",
                  };
                } else {
                  data = {
                    ...values,
                    dateofbirth: convertDate(values?.dateofbirth),
                    teacheradharcard: aadharcardimage.length ? aadharcardimage[0]?.file : "",
                  };
                }
                let res;
                if (id) {
                  res = await dispatch(UpdateTeacher({ id: id, data: data }));
                } else {
                  res = await dispatch(AddTeacherRecord(data));
                }

                if (res?.type === "students/AddTeacherRecord/fulfilled") {
                  toast.success("Teacher Inserted Successfully", {
                    autoClose: 3000,
                  });
                  await dispatch(GetAllTeachersList());
                  setOpenModal(false);
                } else if (
                  res?.type === "students/UpdateTeacher/fulfilled"
                ) {
                  toast.success("Teacher Updated Successfully", {
                    autoClose: 3000,
                  });
                  await dispatch(GetAllTeachersList());
                  return navigate("/adminpanel/teacher");
                } else {
                  toast.error("Something Went Wrong", {
                    autoClose: 3000,
                  });
                }
              }}
            >
              {({ values, errors, touched, setFieldValue, handleChange }) => (
                <Form>
                  <div className="row p-2">

                  <h4>Personal Details</h4>
                    <hr />

                    <div className="form-outline col-md-6 flex-fill">
                      <label className="form-label" htmlFor="teachername">
                        Teacher Name
                        <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        name="teachername"
                        className="form-control"
                        id="teachername"
                        onChange={handleChange}
                        value={values?.teachername}
                      />
                      {touched?.teachername && errors?.teachername ? (
                        <div className="error">{errors?.teachername}</div>
                      ) : null}
                    </div>

                    <div className="form-outline col-6 flex-fill">
                      <label className="form-label dob" htmlFor="dateofbirth">
                        Date Of Birth
                        <span className="error">*</span>
                      </label>
                      <input
                        type="date"
                        name="dateofbirth"
                        className="form-control form_input"
                        id="dateofbirth"
                        onChange={handleChange}
                        value={values?.dateofbirth}
                      />
                      {touched?.dateofbirth && errors?.dateofbirth ? (
                        <div className="error">{errors?.dateofbirth}</div>
                      ) : null}
                    </div>

                    <div className="form-outline col-md-6 flex-fill">
                      <label className="form-label" htmlFor="mobileno">
                      Mobile no
                        <span className="error">*</span>
                      </label>
                      <input
                        type="number"
                        name="mobileno"
                        className="form-control"
                        id="mobileno"
                        onChange={handleChange}
                        value={values?.mobileno}
                      />
                      {touched?.mobileno && errors?.mobileno ? (
                        <div className="error">{errors?.mobileno}</div>
                      ) : null}
                    </div>

                    <div className="form-outline col-md-6 flex-fill">
                      <label className="form-label">
                        Gender<span className="error">*</span>
                      </label>{" "}
                      <br />
                      <div className="form-check mx-1 form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          id="male"
                          value="Male"
                          checked={values.gender === "Male"}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="male">
                          Male
                        </label>
                      </div>
                      <div className="form-check mx-1 form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          id="female"
                          value="Female"
                          checked={values.gender === "Female"}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="female">
                          Female
                        </label>
                      </div>
                    </div>

                    <div className="form-outline col-md-6 flex-fill">
                      <label className="form-label" htmlFor="email">
                        Email
                        <span className="error">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        onChange={handleChange}
                        value={values?.email}
                      />
                      {touched?.email && errors?.email ? (
                        <div className="error">{errors?.email}</div>
                      ) : null}
                    </div>

                    <div className="form-outline col-md-6 flex-fill"></div>

                    <div className="form-outline col-md-6 flex-fill">
                      <label className="form-label" htmlFor="address">
                        Address
                        <span className="error">*</span>
                      </label>
                      <textarea
                        name="address"
                        id="address"
                        className="form-control"
                        cols="30"
                        rows="5"
                        onChange={handleChange}
                        value={values?.address}
                      ></textarea>
                      {touched?.address && errors?.address ? (
                        <div className="error">{errors?.address}</div>
                      ) : null}
                    </div>

                    <div data-mdb-input-init className="form-outline col-md-6">
                      <div className="row flex-row align-items-center mb-4">
                        <div className="form-outline col-6 flex-fill">
                          <label className="form-label" htmlFor="fatherimage">
                            Teacher Aadharcard Image (Size between 1mb to 3mb)
                          </label>
                          <SingleImageUploder
                            singleSelectedImgs={aadharcardimage}
                            setSingleImages={setAadharcardimage}
                            editDataImage={getSingleTeacherData?.teacheradharcard}
                          />
                        </div>
                      </div>
                      {/* {touched?.teacheradharcard && errors?.teacheradharcard ? (
                        <div className="error">{errors?.teacheradharcard}</div>
                      ) : null} */}
                    </div>

                    

                    <h4>Bank Details</h4>
                    <hr />

                    <div className="form-outline col-md-6 flex-fill">
                      <label className="form-label" htmlFor="bankname">
                        Bank Namee
                      </label>
                      <input
                        type="text"
                        name="bankname"
                        className="form-control"
                        id="bankname"
                        onChange={handleChange}
                        value={values?.bankname}
                      />
                      {touched?.bankname && errors?.bankname ? (
                        <div className="error">{errors?.bankname}</div>
                      ) : null}
                    </div>

                    <div className="form-outline col-md-6 flex-fill">
                      <label className="form-label" htmlFor="accountnumber">
                      Account Number
                      </label>
                      <input
                        type="text"
                        name="accountnumber"
                        className="form-control"
                        id="accountnumber"
                        onChange={handleChange}
                        value={values?.accountnumber}
                      />
                      {touched?.accountnumber && errors?.accountnumber ? (
                        <div className="error">{errors?.accountnumber}</div>
                      ) : null}
                    </div>

                    <div className="form-outline col-md-6 flex-fill">
                      <label className="form-label" htmlFor="accounttype">
                      Account Type
                      </label>
                      <input
                        type="text"
                        name="accounttype"
                        className="form-control"
                        id="accounttype"
                        onChange={handleChange}
                        value={values?.accounttype}
                      />
                      {touched?.accounttype && errors?.accounttype ? (
                        <div className="error">{errors?.accounttype}</div>
                      ) : null}
                    </div>

                    <div className="form-outline col-md-6 flex-fill">
                      <label className="form-label" htmlFor="branchname">
                      Branch Name
                      </label>
                      <input
                        type="text"
                        name="branchname"
                        className="form-control"
                        id="branchname"
                        onChange={handleChange}
                        value={values?.branchname}
                      />
                      {touched?.branchname && errors?.branchname ? (
                        <div className="error">{errors?.branchname}</div>
                      ) : null}
                    </div>

                    <div className="form-outline col-md-6 flex-fill">
                      <label className="form-label" htmlFor="branchaddress">
                        Branch Address
                      </label>
                      <textarea
                        name="branchaddress"
                        id="branchaddress"
                        className="form-control"
                        cols="30"
                        rows="3"
                        onChange={handleChange}
                        value={values?.branchaddress}
                      ></textarea>
                      {touched?.branchaddress && errors?.branchaddress ? (
                        <div className="error">{errors?.branchaddress}</div>
                      ) : null}
                    </div>
                    <div className="form-outline col-md-6 flex-fill">
                      <label className="form-label" htmlFor="IFSCcode">
                      IFSC Code
                      </label>
                      <input
                        type="text"
                        name="IFSCcode"
                        className="form-control"
                        id="IFSCcode"
                        onChange={handleChange}
                        value={values?.IFSCcode}
                      />
                      {touched?.IFSCcode && errors?.IFSCcode ? (
                        <div className="error">{errors?.IFSCcode}</div>
                      ) : null}
                    </div>

                  </div>
                  <div className="d-flex justify-content-end">
                    {id ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-thmdanger mx-2"
                          onClick={() => navigate("/adminpanel/teacher")}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-thmdanger mx-2"
                          onClick={() => setOpenModal(false)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                    {id ? (
                      <>
                        <input
                          type="submit"
                          id="addstudent"
                          name="submit"
                          value="Update Teacher"
                          className="btn btn-thm"
                        />
                      </>
                    ) : (
                      <>
                        <input
                          type="submit"
                          id="addstudent"
                          name="submit"
                          value="Add Teacher"
                          className="btn btn-thm"
                        />
                      </>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddTeacher
