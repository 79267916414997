import React, { useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import FeesPayment from "../Components/FeesPayment/FeesPayment";

const StudentFee = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = Cookies.get("scholar-auth-token");
    if(!accessToken){
      navigate("/adminpanel");
    }
  }, [navigate]);

  return (
    <>
      <Sidebar />
      <div className=" home-section">
        <div className="container-fluid">
            <div className="col-lg-12 mainpage-padding py-3 px-4">
              <h1>Student Fee</h1>
                <FeesPayment />
                
            </div>
        </div>
      </div>
    </>
  );
};

export default StudentFee;
