import React, { useEffect, useState } from "react";
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import CustomModal from "../Components/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteStudent,
  GetAllStandardMedium,
  GetStudentFilterData,
  GetStudentList,
  GetStudentProgress,
  GetTopicList,
} from "../Features/properties/StudentService";
import Sidebar from "../Components/Sidebar";
import DeleteModal from "../Components/DeleteModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";
import { CSVLink } from "react-csv";
import Cookies from "js-cookie";
import ReactPaginate from "react-paginate";
import AddStudent from "../Components/Students/AddStudent";

const Students = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isShowDeleteModal, setShowDeleteModal] = useState(false);
  const [isShowProgressModal, setShowProgressModal] = useState(false);
  const [isIsId, setIsId] = useState(0);
  const [progressName, setProgressName] = useState("");
  const [standard, setStandard] = useState("");
  const [medium, setMedium] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const studentsPerPage = 10;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getStudentLoginDetail, getAllStandardMedium, getStudentList, getStudentProgress, getTopicList, loading } = useSelector(
    (state) => state.students
  );

  useEffect(() => {
    const accessToken = Cookies.get("scholar-auth-token");
    if (!accessToken) {
      navigate("/adminpanel");
    }
  }, [navigate]);

  useEffect(() => {
    dispatch(GetStudentFilterData({ standard, medium }));
    dispatch(GetTopicList());
    dispatch(GetAllStandardMedium());
    setPageNumber(0);
  }, [dispatch, standard, medium]);
  

  useEffect(() => {
    if (getStudentLoginDetail) {
      setOpenPasswordModal(true);
      console.log(getStudentLoginDetail);
    }
  }, [getStudentLoginDetail])

  const data = React.useMemo(() => getStudentList || [], [getStudentList]);

  console.log(getAllStandardMedium);

  const columns = React.useMemo(
    () => [
      {
        Header: "No",
        accessor: (row, index) => index + 1,
      },
      {
        Header: "Image",
        accessor: "studentimage",
        Cell: ({ value }) => (
          <img
            alt="..."
            src={`https://shreescholarstarnursery.in/${value}`}
            className="img-thumbnail studImage-preview"
          />
        ),
      },
      { Header: "GR No", accessor: "gr" },
      { Header: "Student Name", accessor: "studentname" },
      { Header: "Father Name", accessor: "parent_details.fathername" },
      { Header: "Mother Name", accessor: "parent_details.mothername" },
      { Header: "Gender", accessor: "gender" },
      { Header: "DOB", accessor: "dateofbirth" },
      { Header: "AGE", accessor: "age" },
      { Header: "Contact No", accessor: "parent_details.fatherno" },
      {
        Header: "Address",
        accessor: "address",
        Cell: ({ value }) => (
          <span
            className="text-wrap d-inline-block address-width"
            data-bs-toggle="tooltip"
            title={value}
          >
            {value.length > 40 ? value.substring(0, 40) + "..." : value}
          </span>
        ),
      },
      { Header: "Date Of Admission", accessor: "dateofadmission" },
      { Header: "Standard", accessor: "standard" },
      { Header: "Medium", accessor: "medium" },
      {
        Header: "Total Fee",
        accessor: (row) =>
          row.totalfeesfromform !== "" ? row.totalfeesfromform : row.totalFees,
      },
      {
        Header: "Paid Fee",
        accessor: (row) =>
          parseInt(row?.payment_details.map(payment => parseFloat(payment.payingfees))
        .reduce((acc, curr) => acc + curr, 0))
      },
      {
        Header: "Pending Fee",
        accessor: (row) =>
          parseInt(row?.payment_details.length === 0
            ? row?.totalfeesfromform !== ""
              ? row?.totalfeesfromform
              : row?.totalFees
            : parseInt(row?.totalfeesfromform !== ""
              ? row?.totalfeesfromform
              : row?.totalFees) -
            parseInt(row?.payment_details.map(payment => parseFloat(payment.payingfees))
              .reduce((acc, curr) => acc + curr, 0)))
          
      },
      {
        Header: "Action",
        accessor: (row) => row._id,
        Cell: ({ row }) => (
          <div>
            <button
              className="btn btn-thmgreen mx-2"
              onClick={() => navigate(`/adminpanel/editstudent?studentid=${row.original._id}`)}
            >
              EDIT
            </button>
            <button
              className="btn btn-thmdanger"
              onClick={(e) => handleOnDelete(e, row.original._id, row.original.studentname)}
            >
              DELETE
            </button>
            <button
              className="btn btn-thm mx-2"
              onClick={(e) => handleOnProgress(e, row.original._id, row.original.studentname)}
            >
              View Progress
            </button>
          </div>
        ),
      },
    ],
    [navigate]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    state: { globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: pageNumber, pageSize: studentsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    gotoPage(selected);
  };

  const handlePasswordModalClose = () => {
    setOpenPasswordModal(false);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleProgressModalClose = () => {
    setShowProgressModal(false);
  };

  const handleAddStudent = () => {
    setOpenModal(true);
  };

  const handleOnDelete = (e, id, studentName) => {
    e.preventDefault();
    e.stopPropagation();
    setProgressName(studentName);
    setShowDeleteModal(true);
    setIsId(id);
  };

  const handleOnDeleteSave = async () => {
    const res = await dispatch(DeleteStudent(isIsId));
    if (res?.type === "students/DeleteStudent/fulfilled") {
      setShowDeleteModal(false);
      toast.success("Student Deleted Successfully", {
        autoClose: 3000,
      });
      setIsId(0);
      await dispatch(GetStudentList());
    } else {
      toast.error("Something Went Wrong", {
        autoClose: 3000,
      });
    }
  };

  const handleStandardChange = (event) => {
    const selectedStandard = event.target.value;
    setStandard(selectedStandard);
  };

  const handleMediumChange = (event) => {
    const selectedMedium = event.target.value;
    setMedium(selectedMedium);
  };

  const handleOnProgress = (e, id, studentName) => {
    e.preventDefault();
    e.stopPropagation();
    setProgressName(studentName);
    dispatch(GetStudentProgress(id));
    setShowProgressModal(true);
    setIsId(id);
  };

  const findTitleById = (id) => {
    const syllabus = getTopicList.find((item) => item._id === id);
    return syllabus ? syllabus.title : "Title not found";
  };

  const findTopicById = (id) => {
    const syllabus = getTopicList.find((item) => item._id === id);
    return syllabus ? syllabus.topic : "Topic not found";
  };

  const handleGlobalFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setPageNumber(0); // Reset page number when global filter changes
  };
  

  const headers = [
    { label: "GR no", key: "gr" },
    { label: "Name", key: "studentname" },
    { label: "Father Name", key: "parent_details.fathername" },
    { label: "Mother Name", key: "parent_details.mothername" },
    { label: "Gender", key: "gender" },
    { label: "Birth date", key: "dateofbirth" },
    { label: "Age", key: "age" },
    { label: "Mobile no.", key: "parent_details.fatherno" },
    { label: "Address", key: "address" },
    { label: "Admission Date", key: "dateofadmission" },
    { label: "Standard", key: "standard" },
    { label: "Medium", key: "medium" },
    { label: "Total Fees", key: "totalFees" },
    // { label: "Total Fees", key: totalfeesfromform !== "" ? "totalfeesfromform" : "totalFees"},
  ];

  return (
    <>
      <Sidebar />
      <div className="home-section">
        {loading ? <Loader /> : <></>}
        <div className="container-fluid">
          <div className="row py-3 px-md-4 px-2">
            <div className="col-lg-12 mainpage-padding">
              <h1 className="mb-4">Student Details</h1>
              <div>
                <div className="text-end">
                  <button
                    className="btn btn-thm add-btn"
                    onClick={() => handleAddStudent()}
                  >
                    + Add Students
                  </button>
                  <div className="d-flex justify-content-between row mb-3 mt-4 ">
                    <div className="d-md-flex col-md-6">
                      <div
                        data-mdb-input-init
                        className="form-outline filterfield"
                      >
                        <select
                          className="form-select filter-box"
                          name="standard"
                          id="standard"
                          onChange={handleStandardChange}
                          value={standard || ""}
                          aria-label="Default select example"
                        >
                          <option value="">
                            Select Standard
                          </option>
                          {getAllStandardMedium &&
                            getAllStandardMedium.standard &&
                            getAllStandardMedium.standard.map((std, index) => (
                              <option value={std} key={index}>
                                {std}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div data-mdb-input-init className="form-outline filterfield">
                        <select
                          className="form-select filter-box"
                          placeholder="Select medium"
                          name="medium"
                          aria-label="Default select example"
                          onChange={handleMediumChange}
                          value={medium || ""}
                        >
                          <option value="">
                            Select Medium
                          </option>
                          {getAllStandardMedium &&
                            getAllStandardMedium.medium &&
                            getAllStandardMedium.medium.map((med, index) => (
                              <option value={med} key={index}>
                                {med}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div data-mdb-input-init className="col-md-6 d-flex form-outline justify-content-md-end">
                      {/* Search Input Field */}
                      <input
                        type="search"
                        className="form-control filter-box"
                        placeholder="Search..."
                        value={globalFilter || ""}
                        onChange={handleGlobalFilterChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="table-responsive mb-5">
                  <table
                    {...getTableProps()}
                    className="auto-index table text-center table-striped display scroll-tab table-margin"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="table-primary">
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render("Header")}
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? " 🔽"
                                  : " 🔼"
                                : ""}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map(row => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} className="align-middle">
                            {row.cells.map(cell => (
                              <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {getStudentList.length === 0 ? (
                    <h3 className="text-center text-secondary mt-5">
                      No Students found
                    </h3>
                  ) : null}
                  <div className="dt-export">
                    <CSVLink
                      data={getStudentList}
                      headers={headers}
                      filename="Student_List.csv"
                    >
                      <button className="btn btn-thmgreen">
                        Export <span className="mx-1"></span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15px"
                          fill="#ffffff"
                          viewBox="0 0 384 512"
                        >
                          <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                        </svg>
                      </button>
                    </CSVLink>
                  </div>
                  <div className="dt-paging paging_full_numbers h-38px">
                    <ReactPaginate
                      previousLabel={<button className="btn btn-thm">{"<"}</button>}
                      nextLabel={<button className="btn btn-thm">{">"}</button>}
                      breakLabel={<button className="btn btn-thm">{"..."}</button>}
                      pageCount={Math.ceil(data.length / studentsPerPage)}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link"}
                      renderOnZeroPageCount={null}
                      pageClassName={"page-item"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      breakClassName={"page-item"}
                    />
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        title={"Add Student"}
        show={openModal}
        onClose={handleModalClose}
        modalSize="modal-xl size-full"
      >
        <AddStudent setOpenModal={setOpenModal} />
      </CustomModal>
      <CustomModal
        title={`Progress of ${progressName}`}
        show={isShowProgressModal}
        onClose={handleProgressModalClose}
        modalSize="modal-lg size-full"
      >
        <div className="progress-modal">
          <table
            className="auto-index table text-center table-striped display scroll-tab table-margin"
            style={{ width: "100%" }}
          >
            <thead>
              <tr className="table-primary">
                <th>No</th>
                <th>Title</th>
                <th>Topic</th>
                <th>Running</th>
                <th>Teacher</th>
                <th>Parent</th>
              </tr>
            </thead>
            <tbody>
              {getStudentProgress?.progress
                .filter((report) => report?.teacher === "true" || report?.inProgress === "true") // Filter records
                .map((report, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{findTitleById(report?.topicid)}</td>
                    <td>{findTopicById(report?.topicid)}</td>
                    <td>
                      <input type="checkbox" checked={report?.inProgress === "true"} readOnly />
                    </td>
                    <td>
                      <input type="checkbox" checked={report?.teacher === "true"} readOnly />
                    </td>
                    <td>
                      <input type="checkbox" checked={report?.parent === "true"} readOnly />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </CustomModal>
      <DeleteModal
        isShowModal={isShowDeleteModal}
        title={`Delete ${progressName}`}
        handleOnDeleteSave={handleOnDeleteSave}
        setDeleteModal={setShowDeleteModal}
        deleteMsj={`Are you sure you want to delete this Student?`}
      />
      <CustomModal
        title={"Parent Login Detail"}
        show={openPasswordModal}
        onClose={handlePasswordModalClose}
        modalSize="modal-lg size-full"
      >
        <>
          <h2 className="text-success text-center mb-2">Congratulation! Your admission confirmed.</h2>
          <div className="p-5 d-flex justify-content-center">
            <div>
              <h3 className="mb-2">Login Id :- {getStudentLoginDetail?.userid}</h3>
              <h3 className="mt-2">Password :- {getStudentLoginDetail?.finalpass}</h3>
            </div>
          </div>
        </>
      </CustomModal>
    </>
  );
};

export default Students;
