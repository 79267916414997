import React, { useEffect, useState } from "react";
import Loader from "../Components/Loader";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdminLogin } from "../Features/properties/StudentService";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const validate = (values) => {
  const errors = {};

  if (!values.userid) {
    errors.userid = "Please enter user name";
  }

  if (!values.password) {
    errors.password = "Please enter password";
  }

  return errors;
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { loading } = useSelector((state) => state.students);

  useEffect(() => {
    const accessToken = Cookies.get("scholar-auth-token");
    if(accessToken){
      navigate("/adminpanel/dashboard");
    }
  }, [navigate]);

  return (
    <>
      {loading ? <Loader /> : <></>}
      <div className="container-fluid">
      <div className="row">
        <div className="login-page-div">
        <div className="col-md-6 back-half-img">
          <div className="school-logo d-flex align-items-center justify-content-center h-100">
            <img
              // src="./images/logo-login.png"
              // src="../public/Images/logo-login.png"
              src="https://shreescholarstarnursery.in/adminpanel/static/media/logo-login.png"
              alt="school logo"
              width="250"
              height="250"
            />
          </div>
        </div>
        </div>
        <div className="col-md-6">
          <div className="col-lg-6 col-md-6 d-flex justify-content-center align-items-center login-form">
            <div className="login-container p-4 shadowbox-shadow">
              <p className="mb-5 mt-3 text-center login-title">
                <b>Login</b>
              </p>
              <div className="login-form-box">
                <Formik
                  initialValues={{
                    userid: "",
                    password: "",
                  }}
                  validate={validate}
                  enableReinitialize={true}
                  onSubmit={async (values) => {
                    let data = { ...values };
                    const res = await dispatch(AdminLogin(data));
                    if(res?.payload?.statuscode === 200){
                      Cookies.set(
                        "scholar-auth-token",
                        res?.payload?.token
                      );
                      toast.success("Welcome Admin", {
                        autoClose: 3000,
                      });
                      navigate("/adminpanel/dashboard");
                    }else{
                      toast.error("Enter valid Username and Password", {
                        autoClose: 3000,
                      });
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                  }) => (
                    <Form>
                      <div className="mb-4">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control login-inputbox shadow-none"
                            id="userid"
                            name="userid"
                            placeholder="Username"
                            onChange={handleChange}
                            value={values?.userid}
                          />
                          <span className="login-icons">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-person-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                            </svg>
                          </span>
                        </div>
                          {touched?.userid && errors?.userid ? (
                        <div className="error">{errors?.userid}</div>
                      ) : null}
                      </div>
                      <div className="mb-5">
                        <div className="input-group">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control login-inputbox shadow-none"
                            id="password"
                            name="password"
                            placeholder="Password"
                            onChange={handleChange}
                            value={values?.password}
                          />
                          <span className="login-icons">
                            {showPassword ? (
                              <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-eye-slash-fill"
                              viewBox="0 0 16 16"
                                onClick={() => setShowPassword(false)}
                              >
                                <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                              </svg>
                            ) : (
                              <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              className="bi bi-eye-fill"
                              viewBox="0 0 16 16"
                                onClick={() => setShowPassword(true)}
                              >
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                
                              </svg>
                            )}
                          </span>
                        </div>
                          {touched?.password && errors?.password ? (
                        <div className="error">{errors?.password}</div>
                      ) : null}
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-thm w-50 mt-5 login-btn mx-auto"
                        >
                          Login
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Login;
