import DataTable from "datatables.net-bs5";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import Loader from "../Components/Loader";
import { CSVLink } from "react-csv";
import CustomModal from "../Components/Modal";
import DeleteModal from "../Components/DeleteModal";
import AddNotice from "../Components/Notice/AddNotice";
import {
  DeleteNotice,
  GetAllNoticeList,
  UpdateStatus,
} from "../Features/properties/StudentService";
import { toast } from "react-toastify";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import Cookies from "js-cookie";
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import moment from "moment";


const headers = [
  { label: "Title", key: "title" },
  { label: "Notice", key: "notice" },
  { label: "Status", key: "status" },
];

const Notice = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isShowDeleteModal, setShowDeleteModal] = useState(false);
  const [isIsId, setIsId] = useState(0);
  const [deleteName, setDeleteName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAllNoticeList, loading } = useSelector((state) => state.students);

  useEffect(() => {
    const accessToken = Cookies.get("scholar-auth-token");
    if(!accessToken){
      navigate("/adminpanel");
    }
  }, [navigate]);

  useEffect(() => {
    dispatch(GetAllNoticeList());
  }, [dispatch]);

  useEffect(() => {
    if (!loading) {
      const dataTable = new DataTable("#noticeList", {
        // DataTable initialization options
        responsive: true,
        paging: true,
        searching: true,
        // Add more options as needed
      });

      return () => {
        dataTable.destroy();
      };
    }
  }, [loading, getAllNoticeList]);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleAddFeeStructure = () => {
    setOpenModal(true);
  };

  const handleOnDelete = (e, id, notice) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteName(notice);
    setShowDeleteModal(true);
    setIsId(id);
  };

  const handleOnDeleteSave = async () => {
    const res = await dispatch(DeleteNotice(isIsId));
    if (res?.type === "students/DeleteNotice/fulfilled") {
      setShowDeleteModal(false);
      toast.success("Notice Deleted Successfully", {
        autoClose: 3000,
      });
      setIsId(0);
      await dispatch(GetAllNoticeList());
    } else {
      toast.error("Something Went Wrong", {
        autoClose: 3000,
      });
    }
  };

  const handleStatusChange = async (keyId) => {
    getAllNoticeList.map(async (item) => { // mark the arrow function as async
      if (item._id === keyId) {
        const newStatus = item.status === "true" ? "false" : "true";
        const res = await dispatch(UpdateStatus({ data: { status: newStatus }, id: keyId })); // await here
        if (res.type === "students/UpdateStatus/fulfilled") {
          toast.success("Status Updated Successfully", {
            autoClose: 3000,
          });
          await dispatch(GetAllNoticeList());
        }
      }
    });
  };
  

  return (
    <>
      <Sidebar />
      <div className="home-section">
        {loading ? <Loader /> : <></>}
        <div className="container-fluid">
          <div className="row py-3 px-4">
            <div className="col-lg-12">
              <h1 className="mb-4">Notice</h1>
              <div className="text-end mb-1">
                <button
                  className="btn btn-thm add-btn"
                  onClick={() => handleAddFeeStructure()}
                >
                  + Add Notice
                </button>
              </div>

              <div className="table-responsive">
                <table
                  className="auto-index table text-center table-striped display scroll-tab table-margin"
                  id="noticeList"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr className="table-primary">
                      <th>No.</th>
                      <th>Title</th>
                      <th>Notice</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getAllNoticeList.map((notice, index) => (
                      <>
                        <tr className="align-middle" key={index}>
                          <td>{index + 1}</td>
                          <td>{notice?.title}</td>
                          <td
                            className="text-wrap"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title={notice?.notice}
                          >
                            {notice?.notice.length > 20
                              ? notice?.notice.substring(0, 20) + "..."
                              : notice?.notice}
                          </td>
                          <td>
                            {moment(notice?.created).format("DD-MM-YYYY")}
                          </td>
                          <td className="center-status-button">
                            <FormGroup>
                              <FormControlLabel
                                value={index}
                                control={
                                  <Switch
                                    checked={notice?.status === "true"}
                                    onChange={() => handleStatusChange(notice._id)}
                                  />
                                }
                                label={notice.status === "true" ? "Active" : "Inactive"}
                              />
                            </FormGroup>
                          </td>
                          <td>
                            <button
                              className="btn btn-thmgreen mx-2"
                              onClick={() =>
                                navigate(`/adminpanel/editnotice?noticeid=${notice?._id}`)
                              }
                            >
                              EDIT
                            </button>
                            <button
                              className="btn btn-thmdanger"
                              onClick={(e) => handleOnDelete(e, notice?._id, notice?.title)}
                            >
                              DELETE
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
                {getAllNoticeList.length === 0 ? (
                  <>
                    <h3 className="text-center text-secondary mt-5">
                      No Notice found
                    </h3>
                  </>
                ) : (
                  <></>
                )}
                <CSVLink
                  data={getAllNoticeList}
                  headers={headers}
                  filename="Notice_List.csv"
                >
                  <button className="btn btn-thmgreen dt-export">
                    Export <span className="mx-1"></span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15px"
                      fill="#ffffff"
                      viewBox="0 0 384 512"
                    >
                      <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                    </svg>
                  </button>
                </CSVLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        title={"Add Fee Structure"}
        show={openModal}
        onClose={handleModalClose}
        modalSize="modal-md size-full"
      >
        <AddNotice setOpenModal={setOpenModal} />
      </CustomModal>
      <DeleteModal
        isShowModal={isShowDeleteModal}
        title="Delete Notice"
        handleOnDeleteSave={handleOnDeleteSave}
        setDeleteModal={setShowDeleteModal}
        deleteMsj={`Are you sure you want to delete ${deleteName} Notice?`}
      />
    </>
  );
};

export default Notice;
