import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import {
  Addfeestructure,
  GetFeeStructureList,
  GetSingleFeeStructureData,
  UpdateFeeStructure,
} from "../../Features/properties/StudentService";

const AddFeeStructure = ({ setOpenModal, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getSingleFeeStructureData, loading } = useSelector(
    (state) => state.students
  );

  useEffect(() => {
    if (id) {
      dispatch(GetSingleFeeStructureData(id));
    }
  }, [id, dispatch]);

  const validate = (values) => {
    const errors = {};

    if (!values.standard) {
      errors.standard = "This field is required";
    }
    if (!values.medium) {
      errors.medium = "This field is required";
    }
    if (!values.fees) {
      errors.fees = "This field is required";
    }

    return errors;
  };
  
  return (
    <>
      <div className="col-lg-12">
        {loading ? <Loader /> : <></>}
        <div className="my_dashboard_review">
          <div className="row mb20">
            <Formik
              initialValues={{
                standard: id ? getSingleFeeStructureData?.standard : "" || "",
                medium: id ? getSingleFeeStructureData?.medium : "" || "",
                fees: id ? getSingleFeeStructureData?.fees : "" || "",
              }}
              validate={validate}
              enableReinitialize={true}
              onSubmit={async (values) => {
                let data = { ...values };
                let res;
                if (id) {
                  res = await dispatch(
                    UpdateFeeStructure({ id: id, data: data })
                  );
                } else {
                  res = await dispatch(Addfeestructure(data));
                }

                if (res?.type === "students/Addfeestructure/fulfilled") {
                  toast.success("Fee Structure Inserted Successfully", {
                    autoClose: 3000,
                  });
                  await dispatch(GetFeeStructureList());
                  setOpenModal(false);
                } else if (
                  res?.type === "students/UpdateFeeStructure/fulfilled"
                ) {
                  toast.success("Fee Structure Updated Successfully", {
                    autoClose: 3000,
                  });
                  await dispatch(GetFeeStructureList());
                  return navigate("/adminpanel/feestructure");
                } else {
                  toast.error("Something Went Wrong", {
                    autoClose: 3000,
                  });
                }
              }}
            >
              {({ values, errors, touched, setFieldValue, handleChange }) => (
                <Form>
                  <div className="row p-2">
                    <div className="form-outline col flex-fill">
                      <label className="form-label" htmlFor="standard">
                        Standard
                        <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        name="standard"
                        className="form-control"
                        id="standard"
                        onChange={handleChange}
                        value={values?.standard}
                      />
                      {touched?.standard && errors?.standard ? (
                        <div className="error">{errors?.standard}</div>
                      ) : null}
                    </div>
                    <div className="form-outline col flex-fill">
                      <label className="form-label" htmlFor="medium">
                        Medium
                        <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        name="medium"
                        className="form-control"
                        id="medium"
                        onChange={handleChange}
                        value={values?.medium}
                      />
                      {touched?.medium && errors?.medium ? (
                        <div className="error">{errors?.medium}</div>
                      ) : null}
                    </div>

                    <div className="form-outline col flex-fill">
                      <label className="form-label" htmlFor="fees">
                        Fees
                        <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        name="fees"
                        className="form-control"
                        id="fees"
                        onChange={handleChange}
                        value={values?.fees}
                      />
                      {touched?.fees && errors?.fees ? (
                        <div className="error">{errors?.fees}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    {id ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-thmdanger mx-2"
                          onClick={() => navigate("/adminpanel/feestructure")}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-thmdanger mx-2"
                          onClick={() => setOpenModal(false)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                    {id ? (
                      <>
                        <input
                          type="submit"
                          id="addstudent"
                          name="submit"
                          value="Update Fee Structure"
                          className="btn btn-thm"
                        />
                      </>
                    ) : (
                      <>
                        <input
                          type="submit"
                          id="addstudent"
                          name="submit"
                          value="Add Fee Structure"
                          className="btn btn-thm"
                        />
                      </>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFeeStructure;
