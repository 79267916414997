import React, { useEffect, useMemo, useState } from 'react'
import Sidebar from '../Components/Sidebar'
import Loader from '../Components/Loader'
import CustomModal from '../Components/Modal'
import DeleteModal from '../Components/DeleteModal'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { DeleteSlider, GetAllSliderList } from '../Features/properties/StudentService'
import { toast } from 'react-toastify'
import AddSlider from '../Components/Slider/AddSlider'
import Cookies from "js-cookie";
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import ReactPaginate from 'react-paginate'

const Slider = () => {
    const [openModal, setOpenModal] = useState(false);
    const [isShowDeleteModal, setShowDeleteModal] = useState(false);
    const [isIsId, setIsId] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pageNumber, setPageNumber] = useState(0);
    const parentsPerPage = 10;
    const { getAllSliderList, loading } = useSelector((state) => state.students);

    useEffect(() => {
      const accessToken = Cookies.get("scholar-auth-token");
      if(!accessToken){
        navigate("/adminpanel");
      }
    }, [navigate]);
  
    useEffect(() => {
      dispatch(GetAllSliderList());
    }, [dispatch]);
  
    const columns = useMemo(
      () => [
        { Header: 'No', accessor: (row, index) => index + 1 },
        {
          Header: 'Image',
          accessor: 'socialimage',
          Cell: ({ row }) => (
            <img
              alt="..."
              src={`https://shreescholarstarnursery.in/${row.original.socialimage}`}
              className="img-thumbnail studImage-preview"
            />
          ),
        },
        { Header: 'Platform', accessor: 'socialimedia' },
        {
          Header: 'Link',
          accessor: 'socialimedialink',
          Cell: ({ row }) => (
            <Link
              className="text-decoration-underline text-primary"
              to={row.original.socialimedialink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {row.original.socialimedialink.length > 30
                ? `${row.original.socialimedialink.substring(0, 30)}...`
                : row.original.socialimedialink}
            </Link>
          ),
        },
        {
          Header: 'Action',
          Cell: ({ row }) => (
            <>
              <button
                className="btn btn-thmgreen mx-2"
                onClick={() => navigate(`/adminpanel/editSlider?sliderid=${row.original._id}`)}
              >
                EDIT
              </button>
              <button className="btn btn-thmdanger" onClick={(e) => handleOnDelete(e, row.original._id)}>
                DELETE
              </button>
            </>
          ),
        },
      ],
      [navigate]
    );

    const data = useMemo(() => getAllSliderList, [getAllSliderList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    state: { globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: pageNumber,
        pageSize: parentsPerPage,
        sortBy: [
          {
            id: 'No', // Specify the column you want to sort by default
            desc: false, // Set to true for descending order
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    gotoPage(selected);
  };

  const handleGlobalFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setPageNumber(0); // Reset page number when global filter changes
  };
  
  
    const handleModalClose = () => {
      setOpenModal(false);
    };
  
    const handleAddSlider = () => {
      setOpenModal(true);
    };
  
    const handleOnDelete = (e, id) => {
      e.preventDefault();
      e.stopPropagation();
      setShowDeleteModal(true);
      setIsId(id);
    };
  
    const handleOnDeleteSave = async () => {
      const res = await dispatch(DeleteSlider(isIsId));
      if (res?.type === "students/DeleteSlider/fulfilled") {
        setShowDeleteModal(false);
        toast.success("Teacher Deleted Successfully", {
          autoClose: 3000,
        });
        setIsId(0);
        await dispatch(GetAllSliderList());
      } else {
        toast.error("Something Went Wrong", {
          autoClose: 3000,
        });
      }
    };
  
  return (
    <>
      <Sidebar />
      <div className="home-section">
        {loading ? <Loader /> : <></>}
        <div className="container-fluid">
          <div className="row py-3 px-4">
            <div className="col-lg-12 mainpage-padding">
              <h1 className="mb-4">Sliders Detail</h1>
              <div className="text-end mb-3">
                <button
                  className="btn btn-thm add-btn"
                  onClick={() => handleAddSlider()}
                >
                  + Add Slider
                </button>
              </div>
              <div className="d-flex justify-content-end mb-2">
                {/* Search Input Field */}
                <input
                  type="search"
                  className="form-control filter-box"
                  placeholder="Search..."
                  value={globalFilter || ""}
                  onChange={handleGlobalFilterChange}
                />
              </div>

              <div className="table-responsive mb-5">
                <table
                  {...getTableProps()}
                  className="auto-index table text-center table-striped display scroll-tab table-margin"
                  style={{ width: "100%" }}
                >
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()} className="table-primary">
                        {headerGroup.headers.map(column => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className="align-middle">
                          {row.cells.map(cell => (
                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {getAllSliderList.length === 0 ? (
                  <h3 className="text-center text-secondary mt-5">
                    No Slider found
                  </h3>
                ) : null}
                <div className="dt-export">
                  <ReactPaginate
                    previousLabel={<button className="btn btn-thm">{"<"}</button>}
                    nextLabel={<button className="btn btn-thm">{">"}</button>}
                    breakLabel={<button className="btn btn-thm">{"..."}</button>}
                    pageCount={Math.ceil(data.length / parentsPerPage)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageLinkClassName={"page-link"}
                    renderOnZeroPageCount={null}
                    pageClassName={"page-item"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    breakClassName={"page-item"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        title={"Add Slider"}
        show={openModal}
        onClose={handleModalClose}
        modalSize="modal-md size-full"
      >
        <AddSlider setOpenModal={setOpenModal} />
      </CustomModal>
      <DeleteModal
        isShowModal={isShowDeleteModal}
        title="Delete Slider"
        handleOnDeleteSave={handleOnDeleteSave}
        setDeleteModal={setShowDeleteModal}
        deleteMsj={"Are you sure you want to delete this Slider?"}
      />
    </>
  )
}

export default Slider
