import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Loader from "../Components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AddSyllabus from "../Components/Syllabus/AddSyllabus";
import Cookies from "js-cookie";

const EditSyllabus = () => {
  const { loading } = useSelector(
    (state) => state.students
  );
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [studId, setStudId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = Cookies.get("scholar-auth-token");
    if(!accessToken){
      navigate("/adminpanel");
    }
  }, [navigate]);

  useEffect(() => {
    setStudId(searchParams.get("syllabusid"));
  }, [searchParams]);

  return (
    <>
      <Sidebar />
      {loading ? <Loader /> : <></>}
      <div className="home-section">
        <div className="container-fluid">
          <div className="row py-3 px-4">
            <div className="col-lg-12 mainpage-padding">
              <h1 className="mb-4">Edit Syllabus</h1>
              <AddSyllabus id={studId} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSyllabus;
