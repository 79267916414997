import React from "react";
import CustomModal from "./Modal";

const DeleteModal = ({
  isShowModal,
  title,
  handleOnDeleteSave,
  setDeleteModal,
  deleteMsj,
}) => {
  return (
    <>
      <CustomModal
        title={title}
        show={isShowModal}
        onClose={() => setDeleteModal(false)}
        modalSize="modal-md"
      >
        <div className="row">
          <div>
            <p className="fz24">{deleteMsj}</p>
          </div>
          <div className="text-right-txt">
          <button
              className="btn btn-thmdanger mx-2"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn bgc-thm btn-thm color-white mx-2"
              onClick={(e) => {
                e.preventDefault();
                handleOnDeleteSave();
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default DeleteModal;
