import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuthHeader } from "../../Utils/GetHeader";

const url = "https://shreescholarstarnursery.in/api";

export const AddStudentRecord = createAsyncThunk(
  "students/AddStudentRecord",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/addstudent`, args,{
        headers: getAuthHeader("multipart/form-data"),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetStudentList = createAsyncThunk(
  "students/GetStudentList",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/getallstudentsfeesdetails`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetStudentFeeDetail = createAsyncThunk(
  "students/GetStudentFeeDetail",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/filterstudents?fatherno=${args}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const DeleteStudent = createAsyncThunk(
  "students/DeleteStudent",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.delete(`${url}/deletestudent/${args}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetSingleStudentData = createAsyncThunk(
  "students/GetSingleStudentData",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/getsinglestudentfeesdetails/${args}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const MakePayment = createAsyncThunk(
  "students/MakePayment",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/feespayment`, args,{
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const UpdateStudentRecord = createAsyncThunk(
  "students/UpdateStudentRecord",
  async (args, { rejectWithValue }) => {
    const {data, id} = args;
    try {
      const resp = await axios.put(`${url}/update/${id}`, data,{
        headers: getAuthHeader("multipart/form-data"),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const removeStudentRecord = createAsyncThunk(
  "students/removeStudentRecord",
  async (_) => {
      return "remove";
  }
);

export const AddTopic = createAsyncThunk(
  "students/AddTopic",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/addsyllabus`, args,{
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetTopicList = createAsyncThunk(
  "students/GettopicList",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/allsyllabus`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const DeleteTopic = createAsyncThunk(
  "students/DeleteTopic",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.delete(`${url}/deletesyllabus/${args}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetSingleTopicData = createAsyncThunk(
  "students/GetSingleTopicData",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/singlesyllabus/${args}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const removeTopicRecord = createAsyncThunk(
  "students/removeTopicRecord",
  async (_) => {
      return "remove";
  }
);

export const UpdateTpoic = createAsyncThunk(
  "students/UpdateTpoic",
  async (args, { rejectWithValue }) => {
    const {data, id} = args;
    try {
      const resp = await axios.put(`${url}/updatesyllabus/${id}`, data,{
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetStudentFilterData = createAsyncThunk(
  "students/GetStudentFilterData",
  async (args, { rejectWithValue }) => {
    const {standard, medium} = args;
    try {
      const resp = await axios.get(`${url}/getstudentbystandardandmedium?standard=${standard}&medium=${medium}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetTopicFilterData = createAsyncThunk(
  "students/GetTopicFilterData",
  async (args, { rejectWithValue }) => {
    const {standard, medium} = args;
    try {
      const resp = await axios.get(`${url}/getsyllabusbystandardandmedium?standard=${standard}&medium=${medium}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const removeStudentsRecord = createAsyncThunk(
  "students/removeStudentsRecord",
  async (_) => {
      return "remove";
  }
);

export const GetFeeStructureList = createAsyncThunk(
  "students/GetFeeStructureList",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/allfeestructure`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const Addfeestructure = createAsyncThunk(
  "students/Addfeestructure",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/addfeestructure`, args,{
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const DeleteFeeStructure = createAsyncThunk(
  "students/DeleteFeeStructure",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.delete(`${url}/deletefeestructure/${args}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetSingleFeeStructureData = createAsyncThunk(
  "students/GetSingleFeeStructureData",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/singlefeestructure/${args}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const UpdateFeeStructure = createAsyncThunk(
  "students/UpdateFeeStructure",
  async (args, { rejectWithValue }) => {
    const {data, id} = args;
    try {
      const resp = await axios.put(`${url}/updatefeestructure/${id}`, data,{
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const AddNoticeRecord = createAsyncThunk(
  "students/AddNoticeRecord",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/addnotice`, args,{
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetAllNoticeList = createAsyncThunk(
  "students/GetAllNoticeList",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/allnotice`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const DeleteNotice = createAsyncThunk(
  "students/DeleteNotice",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.delete(`${url}/deletenotice/${args}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetSingleNoticeData = createAsyncThunk(
  "students/GetSingleNoticeData",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/singlenotice/${args}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const UpdateNotice = createAsyncThunk(
  "students/UpdateNotice",
  async (args, { rejectWithValue }) => {
    const {data, id} = args;
    try {
      const resp = await axios.put(`${url}/updatenotice/${id}`, data,{
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const UpdateStatus = createAsyncThunk(
  "students/UpdateStatus",
  async (args, { rejectWithValue }) => {
    const {data, id} = args;
    try {
      const resp = await axios.patch(`${url}/updatenoticestatus/${id}`, data,{
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetAllTeachersList = createAsyncThunk(
  "students/GetAllTeachersList",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/getallteacher`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const AddTeacherRecord = createAsyncThunk(
  "students/AddTeacherRecord",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/addteacher`, args,{
        headers: getAuthHeader("multipart/form-data"),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const DeleteTeacher = createAsyncThunk(
  "students/DeleteTeacher",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.delete(`${url}/deleteteacher/${args}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetSingleTeacherData = createAsyncThunk(
  "students/GetSingleTeacherData",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/getsingleteacher/${args}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const UpdateTeacher = createAsyncThunk(
  "students/UpdateTeacher",
  async (args, { rejectWithValue }) => {
    const {data, id} = args;
    try {
      const resp = await axios.put(`${url}/updateteacher/${id}`, data,{
        headers: getAuthHeader("multipart/form-data"),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const removeTeacherRecord = createAsyncThunk(
  "students/removeTeacherRecord",
  async (_) => {
      return "remove";
  }
);

export const GetAllSliderList = createAsyncThunk(
  "students/GetAllSliderList",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/getallsocialmedia`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const DeleteSlider = createAsyncThunk(
  "students/DeleteSlider",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.delete(`${url}/deletesocialimage/${args}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const AddSliderRecord = createAsyncThunk(
  "students/AddSliderRecord",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/uploadsocialimage`, args,{
        headers: getAuthHeader("multipart/form-data"),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const UpdateSlider = createAsyncThunk(
  "students/UpdateSlider",
  async (args, { rejectWithValue }) => {
    const {data, id} = args;
    try {
      const resp = await axios.put(`${url}/updatesocialimage/${id}`, data,{
        headers: getAuthHeader("multipart/form-data"),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetSingleSliderData = createAsyncThunk(
  "students/GetSingleSliderData",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/getsinglesocialmedia/${args}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const AdminLogin = createAsyncThunk(
  "students/AdminLogin",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.post(`${url}/adminlogin`, args,{
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetStudentProgress = createAsyncThunk(
  "students/GetStudentProgress",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/singlestudentprogress?id=${args}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetAllParentsList = createAsyncThunk(
  "students/GetAllParentsList",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/getallparent`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const ParentPasswordReset = createAsyncThunk(
  "students/ParentPasswordReset",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.patch(`${url}/parentreset`, args,{
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const TeacherPasswordReset = createAsyncThunk(
  "students/TeacherPasswordReset",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.put(`${url}/teacherreset`, args,{
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const DashboardData = createAsyncThunk(
  "students/DashboardData",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/dashBoard`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetAllPayments = createAsyncThunk(
  "students/GetAllPayments",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/getallfeespayment`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetAllStandardMedium = createAsyncThunk(
  "students/GetAllStandardMedium",
  async (_, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/filterstandardandmedium`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const DeletePayment = createAsyncThunk(
  "students/DeletePayment",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.delete(`${url}/deletefeespayment?id=${args}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const GetSinglePaymentData = createAsyncThunk(
  "students/GetSinglePaymentData",
  async (args, { rejectWithValue }) => {
    try {
      const resp = await axios.get(`${url}/singlefeesdetails/${args}`, {
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const UpdatePayment = createAsyncThunk(
  "students/UpdatePayment",
  async (args, { rejectWithValue }) => {
    console.log(args);
    const {data, id} = args;
    console.log(data, " ++++++++++++");
    console.log(id, " ++++++++++++");
    try {
      const resp = await axios.put(`${url}/updatefeespayment?id=${id}`, data,{
        headers: getAuthHeader(),
      });
      return resp.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);


const StudentSlice = createSlice({
  name: "students",
  initialState: {
    error: false,
    loading: false,
    getStudentList: [],
    getStudentFeeDetail: [],
    getSingleStudentData: null,
    getTopicList: [],
    getSingleTopicData: null,
    getFeeStructureList: [],
    getSingleFeeStructureData: null,
    getAllNoticeList: [],
    getSingleNoticeData: null,
    getAllTeachersList: [],
    getSingleTeacherData: null,
    getAllSliderList: [],
    getSingleSliderData: null,
    getToken: null,
    getStudentProgress: null,
    getAllParentsList: [],
    getDashboardData: null,
    getAllPayments: [],
    getAllStandardMedium: [],
    getStudentLoginDetail: null,
    getSinglePaymentData: null,
  },
  reducers: {
    setStudentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(AddStudentRecord.pending, (state) => {
      state.loading = true;
    })
    .addCase(AddStudentRecord.fulfilled, (state, action) => {
      state.loading = false;
      state.getStudentLoginDetail = action?.payload;
    })
    .addCase(AddStudentRecord.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetStudentList.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetStudentList.fulfilled, (state, action) => {
      state.loading = false;
      state.getStudentList = action?.payload?.result;
    })
    .addCase(GetStudentList.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetStudentFeeDetail.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetStudentFeeDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.getStudentFeeDetail = action?.payload?.student?.children_details;
    })
    .addCase(GetStudentFeeDetail.rejected, (state) => {
      state.loading = false;
    })
    .addCase(DeleteStudent.pending, (state) => {
      state.loading = true;
    })
    .addCase(DeleteStudent.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(DeleteStudent.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetSingleStudentData.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetSingleStudentData.fulfilled, (state, action) => {
      state.loading = false;
      state.getSingleStudentData = action?.payload;
    })
    .addCase(GetSingleStudentData.rejected, (state) => {
      state.loading = false;
    })
    .addCase(MakePayment.pending, (state) => {
      state.loading = true;
    })
    .addCase(MakePayment.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(MakePayment.rejected, (state) => {
      state.loading = false;
    })
    .addCase(UpdateStudentRecord.pending, (state) => {
      state.loading = true;
    })
    .addCase(UpdateStudentRecord.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(UpdateStudentRecord.rejected, (state) => {
      state.loading = false;
    })
    .addCase(removeStudentRecord.pending, (state) => {
      state.loading = true;
    })
    .addCase(removeStudentRecord.fulfilled, (state, action) => {
      state.loading = false;
      state.getSingleStudentData = null;
    })
    .addCase(removeStudentRecord.rejected, (state) => {
      state.loading = false;
    })
    .addCase(AddTopic.pending, (state) => {
      state.loading = true;
    })
    .addCase(AddTopic.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(AddTopic.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetTopicList.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetTopicList.fulfilled, (state, action) => {
      state.loading = false;
      state.getTopicList = action?.payload?.allsyllabus;
    })
    .addCase(GetTopicList.rejected, (state) => {
      state.loading = false;
    })
    .addCase(DeleteTopic.pending, (state) => {
      state.loading = true;
    })
    .addCase(DeleteTopic.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(DeleteTopic.rejected, (state) => {
      state.loading = false;
    })
    .addCase(UpdateTpoic.pending, (state) => {
      state.loading = true;
    })
    .addCase(UpdateTpoic.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(UpdateTpoic.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetSingleTopicData.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetSingleTopicData.fulfilled, (state, action) => {
      state.loading = false;
      state.getSingleTopicData = action?.payload?.syllabus;
    })
    .addCase(GetSingleTopicData.rejected, (state) => {
      state.loading = false;
    })
    .addCase(removeTopicRecord.pending, (state) => {
      state.loading = true;
    })
    .addCase(removeTopicRecord.fulfilled, (state, action) => {
      state.loading = false;
      state.getSingleTopicData = null;
    })
    .addCase(removeTopicRecord.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetStudentFilterData.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetStudentFilterData.fulfilled, (state, action) => {
      state.loading = false;
      state.getStudentList = action?.payload?.result;
    })
    .addCase(GetStudentFilterData.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetTopicFilterData.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetTopicFilterData.fulfilled, (state, action) => {
      state.loading = false;
      state.getTopicList = action?.payload?.syllabus;
    })
    .addCase(GetTopicFilterData.rejected, (state) => {
      state.loading = false;
    })
    .addCase(removeStudentsRecord.pending, (state) => {
      state.loading = true;
    })
    .addCase(removeStudentsRecord.fulfilled, (state, action) => {
      state.loading = false;
      state.getStudentList = [];
    })
    .addCase(removeStudentsRecord.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetFeeStructureList.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetFeeStructureList.fulfilled, (state, action) => {
      state.loading = false;
      state.getFeeStructureList = action?.payload?.result;
    })
    .addCase(GetFeeStructureList.rejected, (state) => {
      state.loading = false;
    })
    .addCase(Addfeestructure.pending, (state) => {
      state.loading = true;
    })
    .addCase(Addfeestructure.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(Addfeestructure.rejected, (state) => {
      state.loading = false;
    })
    .addCase(DeleteFeeStructure.pending, (state) => {
      state.loading = true;
    })
    .addCase(DeleteFeeStructure.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(DeleteFeeStructure.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetSingleFeeStructureData.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetSingleFeeStructureData.fulfilled, (state, action) => {
      state.loading = false;
      state.getSingleFeeStructureData = action?.payload?.result;
    })
    .addCase(GetSingleFeeStructureData.rejected, (state) => {
      state.loading = false;
    })
    .addCase(UpdateFeeStructure.pending, (state) => {
      state.loading = true;
    })
    .addCase(UpdateFeeStructure.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(UpdateFeeStructure.rejected, (state) => {
      state.loading = false;
    })
    .addCase(AddNoticeRecord.pending, (state) => {
      state.loading = true;
    })
    .addCase(AddNoticeRecord.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(AddNoticeRecord.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetAllNoticeList.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetAllNoticeList.fulfilled, (state, action) => {
      state.loading = false;
      state.getAllNoticeList = action?.payload?.result;
    })
    .addCase(GetAllNoticeList.rejected, (state) => {
      state.loading = false;
    })
    .addCase(DeleteNotice.pending, (state) => {
      state.loading = true;
    })
    .addCase(DeleteNotice.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(DeleteNotice.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetSingleNoticeData.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetSingleNoticeData.fulfilled, (state, action) => {
      state.loading = false;
      state.getSingleNoticeData = action?.payload?.result;
    })
    .addCase(GetSingleNoticeData.rejected, (state) => {
      state.loading = false;
    })
    .addCase(UpdateNotice.pending, (state) => {
      state.loading = true;
    })
    .addCase(UpdateNotice.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(UpdateNotice.rejected, (state) => {
      state.loading = false;
    })
    .addCase(UpdateStatus.pending, (state) => {
      state.loading = true;
    })
    .addCase(UpdateStatus.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(UpdateStatus.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetAllTeachersList.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetAllTeachersList.fulfilled, (state, action) => {
      state.loading = false;
      state.getAllTeachersList = action?.payload?.result;
    })
    .addCase(GetAllTeachersList.rejected, (state) => {
      state.loading = false;
    })
    .addCase(AddTeacherRecord.pending, (state) => {
      state.loading = true;
    })
    .addCase(AddTeacherRecord.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(AddTeacherRecord.rejected, (state) => {
      state.loading = false;
    })
    .addCase(DeleteTeacher.pending, (state) => {
      state.loading = true;
    })
    .addCase(DeleteTeacher.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(DeleteTeacher.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetSingleTeacherData.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetSingleTeacherData.fulfilled, (state, action) => {
      state.loading = false;
      state.getSingleTeacherData = action?.payload?.result;
    })
    .addCase(GetSingleTeacherData.rejected, (state) => {
      state.loading = false;
    })
    .addCase(UpdateTeacher.pending, (state) => {
      state.loading = true;
    })
    .addCase(UpdateTeacher.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(UpdateTeacher.rejected, (state) => {
      state.loading = false;
    })
    .addCase(removeTeacherRecord.pending, (state) => {
      state.loading = true;
    })
    .addCase(removeTeacherRecord.fulfilled, (state, action) => {
      state.loading = false;
      state.getSingleTeacherData = null;
    })
    .addCase(removeTeacherRecord.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetAllSliderList.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetAllSliderList.fulfilled, (state, action) => {
      state.loading = false;
      state.getAllSliderList = action?.payload?.result;
    })
    .addCase(GetAllSliderList.rejected, (state) => {
      state.loading = false;
    })
    .addCase(DeleteSlider.pending, (state) => {
      state.loading = true;
    })
    .addCase(DeleteSlider.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(DeleteSlider.rejected, (state) => {
      state.loading = false;
    })
    .addCase(AddSliderRecord.pending, (state) => {
      state.loading = true;
    })
    .addCase(AddSliderRecord.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(AddSliderRecord.rejected, (state) => {
      state.loading = false;
    })
    .addCase(UpdateSlider.pending, (state) => {
      state.loading = true;
    })
    .addCase(UpdateSlider.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(UpdateSlider.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetSingleSliderData.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetSingleSliderData.fulfilled, (state, action) => {
      state.loading = false;
      state.getSingleSliderData = action?.payload?.result;
    })
    .addCase(GetSingleSliderData.rejected, (state) => {
      state.loading = false;
    })
    .addCase(AdminLogin.pending, (state) => {
      state.loading = true;
    })
    .addCase(AdminLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.getToken = action?.payload;
    })
    .addCase(AdminLogin.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetStudentProgress.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetStudentProgress.fulfilled, (state, action) => {
      state.loading = false;
      state.getStudentProgress = action?.payload?.studentProgress[0];
    })
    .addCase(GetStudentProgress.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetAllParentsList.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetAllParentsList.fulfilled, (state, action) => {
      state.loading = false;
      state.getAllParentsList = action?.payload?.result;
    })
    .addCase(GetAllParentsList.rejected, (state) => {
      state.loading = false;
    })
    .addCase(ParentPasswordReset.pending, (state) => {
      state.loading = true;
    })
    .addCase(ParentPasswordReset.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(ParentPasswordReset.rejected, (state) => {
      state.loading = false;
    })
    .addCase(TeacherPasswordReset.pending, (state) => {
      state.loading = true;
    })
    .addCase(TeacherPasswordReset.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(TeacherPasswordReset.rejected, (state) => {
      state.loading = false;
    })
    .addCase(DashboardData.pending, (state) => {
      state.loading = true;
    })
    .addCase(DashboardData.fulfilled, (state, action) => {
      state.loading = false;
      state.getDashboardData = action?.payload?.allCount;
    })
    .addCase(DashboardData.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetAllPayments.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetAllPayments.fulfilled, (state, action) => {
      state.loading = false;
      state.getAllPayments = action?.payload?.result;
    })
    .addCase(GetAllPayments.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetAllStandardMedium.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetAllStandardMedium.fulfilled, (state, action) => {
      state.loading = false;
      state.getAllStandardMedium = action?.payload?.filtervalue;
    })
    .addCase(GetAllStandardMedium.rejected, (state) => {
      state.loading = false;
    })
    .addCase(DeletePayment.pending, (state) => {
      state.loading = true;
    })
    .addCase(DeletePayment.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(DeletePayment.rejected, (state) => {
      state.loading = false;
    })
    .addCase(GetSinglePaymentData.pending, (state) => {
      state.loading = true;
    })
    .addCase(GetSinglePaymentData.fulfilled, (state, action) => {
      state.loading = false;
      state.getSinglePaymentData = action?.payload?.filtervalue;
    })
    .addCase(GetSinglePaymentData.rejected, (state) => {
      state.loading = false;
    })
    .addCase(UpdatePayment.pending, (state) => {
      state.loading = true;
    })
    .addCase(UpdatePayment.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(UpdatePayment.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setStudentPage } = StudentSlice.actions;

export default StudentSlice.reducer;
