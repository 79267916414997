import React, { useEffect, useMemo, useState } from 'react'
import Sidebar from '../Components/Sidebar'
import Loader from '../Components/Loader'
import AddSlider from '../Components/Slider/AddSlider'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Cookies from "js-cookie";

const EditSlider = () => {
  const { loading } = useSelector((state) => state.students);
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [sliderId, setSliderId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = Cookies.get("scholar-auth-token");
    if (!accessToken) {
      navigate("/adminpanel");
    }
  }, [navigate]);

  useEffect(() => {
    setSliderId(searchParams.get("sliderid"));
  }, [searchParams]);
  return (
    <>
      <Sidebar />
      {loading ? <Loader /> : <></>}
      <div className="home-section">
        <div className="container-fluid">
          <div className="row py-3 px-4">
            <div className="col-lg-12 mainpage-padding">
              <h1 className="mb-4">Edit Teacher</h1>
              <AddSlider id={sliderId} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditSlider
