import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Students from './Pages/Students';
import 'bootstrap/dist/css/bootstrap.css';
import StudentFee from './Pages/StudentFee';
import Receipt from './Pages/Receipt';
import EditStudent from './Pages/EditStudent';
import Syllabus from './Pages/Syllabus';
import EditSyllabus from './Pages/EditSyllabus';
import ParentsDetail from './Pages/ParentsDetail';
import FeeStructure from './Pages/FeeStructure';
import EditFeeStructure from './Pages/EditFeeStructure';
import Notice from './Pages/Notice';
import EditNotice from './Pages/EditNotice';
import Teachers from './Pages/Teachers';
import EditTeacher from './Pages/EditTeacher';
import Slider from './Pages/Slider';
import EditSlider from './Pages/EditSlider';
import Login from './Pages/Login';
import { Dashboard } from './Pages/Dashboard';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/adminpanel" element={<Login />} />
          <Route path="/adminpanel/dashboard" element={<Dashboard />} />
          <Route path="/adminpanel/students" element={<Students />} />
          <Route path="/adminpanel/fees" element={<StudentFee />} />
          <Route path="/adminpanel/receipt" element={<Receipt />} />
          <Route path="/adminpanel/editstudent" element={<EditStudent />} />
          <Route path="/adminpanel/syllabus" element={<Syllabus />} />
          <Route path="/adminpanel/editsyllabus" element={<EditSyllabus />} />
          <Route path="/adminpanel/parentsdetail" element={<ParentsDetail /> } />
          <Route path="/adminpanel/feestructure" element={<FeeStructure /> } />
          <Route path="/adminpanel/editfeestructure" element={<EditFeeStructure /> } />
          <Route path="/adminpanel/notice" element={<Notice /> } />
          <Route path="/adminpanel/editnotice" element={<EditNotice /> } />
          <Route path="/adminpanel/teacher" element={<Teachers /> } />
          <Route path="/adminpanel/editTeachers" element={<EditTeacher /> } />
          <Route path="/adminpanel/slider" element={<Slider /> } />
          <Route path="/adminpanel/editSlider" element={<EditSlider /> } />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
