import React, { useEffect, useState, useMemo } from "react";
import Sidebar from "../Components/Sidebar";
import Loader from "../Components/Loader";
import { CSVLink } from "react-csv";
import CustomModal from "../Components/Modal";
import DeleteModal from "../Components/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import { toast } from "react-toastify";
import {
  DeleteFeeStructure,
  GetFeeStructureList,
} from "../Features/properties/StudentService";
import AddFeeStructure from "../Components/FeeStructure/AddFeeStructure";
import Cookies from "js-cookie";
import ReactPaginate from "react-paginate";

const headers = [
  { label: "Standard", key: "standard" },
  { label: "Medium", key: "medium" },
  { label: "Fees", key: "fees" },
];

const FeeStructure = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isShowDeleteModal, setShowDeleteModal] = useState(false);
  const [isIsId, setIsId] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);
  const parentsPerPage = 10;
  const { getFeeStructureList, loading } = useSelector(
    (state) => state.students
  );

  useEffect(() => {
    const accessToken = Cookies.get("scholar-auth-token");
    if(!accessToken){
      navigate("/adminpanel");
    }
  }, [navigate]);

  useEffect(() => {
      dispatch(GetFeeStructureList());
  }, [dispatch]);

  const columns = useMemo(() => [
    { Header: "No", accessor: (row, i) => i + 1 },
    { Header: "Standard", accessor: "standard" },
    { Header: "Medium", accessor: "medium" },
    { Header: "Fees", accessor: "fees" },
    {
      Header: "Action",
      accessor: "_id",
      Cell: ({ cell: { value } }) => (
        <>
          <button
            className="btn btn-thmgreen mx-2"
            onClick={() => navigate(`/adminpanel/editfeestructure?feestructureid=${value}`)}
          >
            EDIT
          </button>
          <button
            className="btn btn-thmdanger"
            onClick={(e) => handleOnDelete(e, value)}
          >
            DELETE
          </button>
        </>
      )
    }
  ], [navigate]);

  const data = useMemo(() => getFeeStructureList, [getFeeStructureList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    state: { globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: pageNumber, pageSize: parentsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    gotoPage(selected);
  };

  const handleGlobalFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setPageNumber(0); // Reset page number when global filter changes
  };  

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleAddFeeStructure = () => {
    setOpenModal(true);
  };

  const handleOnDelete = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDeleteModal(true);
    setIsId(id);
  };

  const handleOnDeleteSave = async () => {
    const res = await dispatch(DeleteFeeStructure(isIsId));
    if (res?.type === "students/DeleteFeeStructure/fulfilled") {
      setShowDeleteModal(false);
      toast.success("Fee Structure Deleted Successfully", {
        autoClose: 3000,
      });
      setIsId(0);
      await dispatch(GetFeeStructureList());
    } else {
      toast.error("Something Went Wrong", {
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <Sidebar />
      <div className="home-section">
        {loading ? <Loader /> : <></>}
        <div className="container-fluid">
          <div className="row py-3 px-4">
            <div className="col-lg-12 mainpage-padding">
              <h1 className="mb-4">Fee Structure</h1>
              <div className="text-end mb-3">
                <button
                  className="btn btn-thm add-btn"
                  onClick={() => handleAddFeeStructure()}
                >
                  + Fee Structure
                </button>
              </div>
              <div className="d-flex justify-content-end mb-2">
                {/* Search Input Field */}
                <input
                  type="search"
                  className="form-control filter-box"
                  placeholder="Search..."
                  value={globalFilter || ""}
                  onChange={handleGlobalFilterChange}
                />
              </div>

              <div className="table-responsive mb-5">
                <table
                  {...getTableProps()}
                  className="auto-index table text-center table-striped display scroll-tab table-margin"
                  style={{ width: "100%" }}
                >
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()} className="table-primary">
                        {headerGroup.headers.map(column => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className="align-middle">
                          {row.cells.map(cell => (
                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {getFeeStructureList.length === 0 ? (
                  <h3 className="text-center text-secondary mt-5">
                    No Fee Structure found
                  </h3>
                ) : null}
                <div className="dt-export">
                  <CSVLink
                    data={getFeeStructureList}
                    headers={headers}
                    filename="FeeStructure_List.csv"
                  >
                    <button className="btn btn-thmgreen">
                      Export <span className="mx-1"></span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15px"
                        fill="#ffffff"
                        viewBox="0 0 384 512"
                      >
                        <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                      </svg>
                    </button>
                  </CSVLink>
                </div>
                <div className="dt-paging paging_full_numbers h-38px">
                  <ReactPaginate
                    previousLabel={<button className="btn btn-thm">{"<"}</button>}
                    nextLabel={<button className="btn btn-thm">{">"}</button>}
                    breakLabel={<button className="btn btn-thm">{"..."}</button>}
                    pageCount={Math.ceil(data.length / parentsPerPage)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageLinkClassName={"page-link"}
                    renderOnZeroPageCount={null}
                    pageClassName={"page-item"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    breakClassName={"page-item"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        title={"Add Fee Structure"}
        show={openModal}
        onClose={handleModalClose}
        modalSize="modal-md size-full"
      >
        <AddFeeStructure setOpenModal={setOpenModal} />
      </CustomModal>
      <DeleteModal
        isShowModal={isShowDeleteModal}
        title="Delete Fee Structure"
        handleOnDeleteSave={handleOnDeleteSave}
        setDeleteModal={setShowDeleteModal}
        deleteMsj={"Are you sure you want to delete this Fee Structure?"}
      />
    </>
  );
};

export default FeeStructure;
