import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import Loader from "../Components/Loader";
import AddNotice from "../Components/Notice/AddNotice";
import Cookies from "js-cookie";

const EditNotice = () => {
  const { loading } = useSelector((state) => state.students);
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [noticeId, setNoticeId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = Cookies.get("scholar-auth-token");
    if (!accessToken) {
      navigate("/adminpanel");
    }
  }, [navigate]);

  useEffect(() => {
    setNoticeId(searchParams.get("noticeid"));
  }, [searchParams]);

  return (
  <>
    <Sidebar />
      {loading ? <Loader /> : <></>}
      <div className="home-section">
        <div className="container-fluid">
          <div className="row py-3 px-4">
            <div className="col-lg-12 mainpage-padding">
              <h1 className="mb-4">Edit Syllabus</h1>
              <AddNotice id={noticeId} />
            </div>
          </div>
        </div>
      </div>
  </>
);
};

export default EditNotice;
