import Cookies from "js-cookie";
import _ from "lodash";

export const getHeader = () => {
  const headers = {
    // "Accept": "application/json",
    // "Content-Disposition": `form-data; name="items"; filename="blob"`,
    "Content-Type": "application/json",
  };
  return headers;
};

export const getAuthHeader = (type) => {
  const token = Cookies.get("scholar-auth-token");
  const headers = {
    "Content-Type" : `${!type ? "application/json" : type}`,
    authorization: `Bearer ${token}`,
  };
  return headers;
};

export const getConvertFormData = (obj, text) => {
  const formData = new FormData();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (key === 'units' && text !== 'contract') {
        _.map(value, (unit, index) => {
          Object.keys(unit).forEach((key) => {
            formData.append(`units[${index}]${key}`, unit[key]);
          });
        });
      } else if(key === 'properties' && (text === 'manager' || text === 'companayVendor')) {
        _.map(value, (item) => {
					formData.append('properties', item);
				});
      } else {
        formData.append(key, value);
      }
    }
  }
  return formData;
};
