import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  AddStudentRecord,
  GetSingleStudentData,
  GetStudentList,
  UpdateStudentRecord,
  removeStudentRecord,
} from "../../Features/properties/StudentService";
import { toast } from "react-toastify";
import moment from "moment";
import SingleImageUploder from "../SingleImageUploder";
import { getConvertFormData } from "../../Utils/GetHeader";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";

const AddStudent = ({ setOpenModal, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [studentimage, setStudentimage] = useState([]);
  const [fatherimage, setFatherimage] = useState([]);
  const [motherimage, setMotherimage] = useState([]);
  const {getAllStandardMedium, getSingleStudentData, loading } = useSelector(
    (state) => state.students
  );
  useEffect(() => {
    if (id) {
      dispatch(GetSingleStudentData(id));
    } else {
      dispatch(removeStudentRecord());
    }
  }, [id, dispatch]);

  const validate = (values) => {
    const errors = {};

    if (!values.studentname) {
      errors.studentname = "This field is required";
    }
    if (!values.dateofbirth) {
      errors.dateofbirth = "This field is required";
    }
    if (!values.placeofbirth) {
      errors.placeofbirth = "This field is required";
    }
    if (!values.gender) {
      errors.gender = "This field is required";
    }
    if (!values.address) {
      errors.address = "This field is required";
    }
    if (!values.firstlanguage) {
      errors.firstlanguage = "This field is required";
    }
    if (!values.gr) {
      errors.gr = "This field is required";
    }
    if (!values.standard) {
      errors.standard = "This field is required";
    }
    if (!values.medium) {
      errors.medium = "This field is required";
    }
    if (!values.dateofadmission) {
      errors.dateofadmission = "This field is required";
    }
    if (!values.fathername) {
      errors.fathername = "This field is required";
    }
    if (!values.fatherqualification) {
      errors.fatherqualification = "This field is required";
    }
    if (!values.fatherno) {
      errors.fatherno = "This field is required";
    }else if(String(values.fatherno).length !== 10){
      errors.fatherno = "Must be 10 Characters";
    }
    if (!values.fatherprofession) {
      errors.fatherprofession = "This field is required";
    }
    if (!values.mothername) {
      errors.mothername = "This field is required";
    }
    if (!values.motherno) {
      errors.motherno = "This field is required";
    }else if(String(values.motherno).length !== 10){
      errors.motherno = "Must be 10 Characters";
    }
    // if (!id) {
    //   if (!studentimage.length) {
    //     errors.studentimage = "Image is Require";
    //   }
    //   if (!fatherimage.length) {
    //     errors.fatherimage = "Image is Require";
    //   }
    //   if (!motherimage.length) {
    //     errors.motherimage = "Image is Require";
    //   }
    // }

    return errors;
  };

  const calculateAge = (dob) => {
    const today = moment();
    const birthDate = moment(dob);
    const years = today.diff(birthDate, "years");
    return years;
  };

  // Utility function to convert date format
  const convertDateFormat = (date) => {
    return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
  };

  const convertDate = (date) => {
    return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
  };

  return (
    <>
      <div className="col-lg-12 mt-1">
        {loading ? <Loader /> : <></>}
        <div className="my_dashboard_review">
          <div className="row mb20">
            <Formik
              initialValues={{
                studentname: id ? getSingleStudentData?.studentname : "" || "",
                dateofbirth: id ? convertDateFormat(getSingleStudentData?.dateofbirth) : "" || "",
                age: id ? getSingleStudentData?.age : "" || "",
                placeofbirth: id
                  ? getSingleStudentData?.placeofbirth
                  : "" || "",
                gender: id ? getSingleStudentData?.gender : "male" || "",
                address: id ? getSingleStudentData?.address : "" || "",
                nationality: id
                  ? getSingleStudentData?.nationality
                  : "Indian" || "",
                firstlanguage: id
                  ? getSingleStudentData?.firstlanguage
                  : "" || "",
                otherlanguage: id
                  ? getSingleStudentData?.otherlanguage
                  : "" || "",
                gr: id ? getSingleStudentData?.gr : "********" || "********",
                standard: id ? getSingleStudentData?.standard : "" || "",
                medium: id ? getSingleStudentData?.medium : "" || "",
                totalfeesfromform: id ? getSingleStudentData?.totalfeesfromform : "" || "",
                dateofadmission: id
                  ? convertDateFormat(getSingleStudentData?.dateofadmission)
                  : "" || "",
                fathername: id ? getSingleStudentData?.parent_details?.fathername : "" || "",
                fatherdateofbirth: id ? convertDateFormat(getSingleStudentData?.parent_details?.fatherdateofbirth) : "" || "",
                fatherqualification: id
                  ? getSingleStudentData?.parent_details?.fatherqualification
                  : "" || "",
                fatherno: id ? getSingleStudentData?.parent_details?.fatherno : "" || "",
                fathergmail: id ? getSingleStudentData?.parent_details?.fathergmail : "" || "",
                fatherprofession: id
                  ? getSingleStudentData?.parent_details?.fatherprofession
                  : "" || "",
                fatherdesignation: id
                  ? getSingleStudentData?.parent_details?.fatherdesignation
                  : "" || "",
                mothername: id ? getSingleStudentData?.parent_details?.mothername : "" || "",
                motherdateofbirth: id ? convertDateFormat(getSingleStudentData?.parent_details?.motherdateofbirth) : "" || "",
                motherqualification: id
                  ? getSingleStudentData?.parent_details?.motherqualification
                  : "" || "",
                motherno: id ? getSingleStudentData?.parent_details?.motherno : "" || "",
                mothergmail: id ? getSingleStudentData?.parent_details?.mothergmail : "" || "",
                motherprofession: id
                  ? getSingleStudentData?.parent_details?.motherprofession
                  : "" || "",
                motherdesignation: id
                  ? getSingleStudentData?.parent_details?.motherdesignation
                  : "" || "",
              }}
              validate={validate}
              enableReinitialize={true}
              onSubmit={async (values) => {
                console.log(values);
                let data = {};
                if (id) {
                  data = {
                    ...values,
                    dateofbirth: convertDate(values?.dateofbirth),
                    dateofadmission: convertDate(values?.dateofadmission),
                    fatherdateofbirth: convertDate(values?.fatherdateofbirth),
                    motherdateofbirth: convertDate(values?.motherdateofbirth),
                    age: String(calculateAge(values.dateofbirth)),
                    studentimage: studentimage.length
                      ? studentimage[0]?.file
                      : "",
                    fatherimage: fatherimage.length ? fatherimage[0]?.file : "",
                    motherimage: motherimage.length ? motherimage[0]?.file : "",
                    student_old_image: getSingleStudentData?.studentimage ? getSingleStudentData?.studentimage : "" ,
                    father_old_image: getSingleStudentData?.parent_details?.fatherimage ? getSingleStudentData?.parent_details?.fatherimage : "",
                    mother_old_image: getSingleStudentData?.parent_details?.motherimage ? getSingleStudentData?.parent_details?.motherimage : "",
                  };
                } else {
                  data = {
                    ...values,
                    dateofbirth: convertDate(values?.dateofbirth),
                    dateofadmission: convertDate(values?.dateofadmission),
                    fatherdateofbirth: convertDate(values?.fatherdateofbirth),
                    motherdateofbirth: convertDate(values?.motherdateofbirth),
                    age: String(calculateAge(values.dateofbirth)),
                    studentimage: studentimage.length
                      ? studentimage[0]?.file
                      : "",
                    fatherimage: fatherimage.length ? fatherimage[0]?.file : "",
                    motherimage: motherimage.length ? motherimage[0]?.file : "",
                  };
                }
                const finalData = await getConvertFormData(data);
                console.log(finalData, "data");
                let res;
                if (id) {
                  res = await dispatch(
                    UpdateStudentRecord({ id: id, data: finalData })
                  );
                } else {
                  res = await dispatch(AddStudentRecord(finalData));
                }

                if (res?.type === "students/AddStudentRecord/fulfilled") {
                  setOpenModal(false);
                  toast.success("Student Added Successfully", {
                    autoClose: 3000,
                  });
                  await dispatch(GetStudentList());
                } else if (
                  res?.type === "students/UpdateStudentRecord/fulfilled"
                ) {
                  toast.success("Student Updated Successfully", {
                    autoClose: 3000,
                  });
                  await dispatch(GetStudentList());
                  return navigate("/adminpanel/students");
                } else {
                  toast.error("Something Went Wrong", {
                    autoClose: 3000,
                  });
                }
              }}
            >
              {({ values, errors, touched, setFieldValue, handleChange }) => (
                <Form>
                  <div className="row p-2">
                    <h5>Personal details</h5>
                    <hr />
                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="studentname">
                        Student Name
                        <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        name="studentname"
                        className="form-control"
                        id="studentname"
                        onChange={handleChange}
                        value={values?.studentname}
                      />
                      {touched?.studentname && errors?.studentname ? (
                        <div className="error">{errors?.studentname}</div>
                      ) : null}
                    </div>
                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label dob" htmlFor="dateofbirth">
                        Date Of Birth
                        <span className="error">*</span>
                      </label>
                      {/* <ReactDatePicker
                        showIcon
                        toggleCalendarOnIconClick
                        selected={values.dateofbirth}
                        name="dateofbirth"
                        id="dateofbirth"
                        className="form-control form_input"
                        onChange={(date) => setFieldValue('dateofbirth', date)}
                      /> */}
                      <input
                        type="date"
                        name="dateofbirth"
                        className="form-control form_input"
                        id="dateofbirth"
                        onChange={handleChange}
                        value={values?.dateofbirth}
                      />
                      {touched?.dateofbirth && errors?.dateofbirth ? (
                        <div className="error">{errors?.dateofbirth}</div>
                      ) : null}
                    </div>
                    {/* <div className="form-outline col-sm-6 flex-fill"> */}
                    {/* <div className="row"> */}
                    {/* <div className="col-7 px-1"> */}

                    {/* </div> */}

                    {/* <div className="form-outline col-5 flex-fill"> */}
                    {/* <label className="form-label" htmlFor="age">
                            Age
                            <span className="error">*</span>
                          </label> */}
                    <input
                      type="hidden"
                      name="age"
                      className="form-control"
                      id="age"
                      onChange={handleChange}
                      value={values?.age}
                    />
                    {/* {touched?.age && errors?.age ? (
                            <div className="error">{errors?.age}</div>
                          ) : null} */}
                    {/* </div> */}
                    {/* </div> */}
                    {/* </div> */}

                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="placeofbirth">
                        Place of Birth
                        <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        name="placeofbirth"
                        className="form-control"
                        id="placeofbirth"
                        onChange={handleChange}
                        value={values?.placeofbirth}
                      />
                      {touched?.placeofbirth && errors?.placeofbirth ? (
                        <div className="error">{errors?.placeofbirth}</div>
                      ) : null}
                    </div>

                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label">
                        Gender<span className="error">*</span>
                      </label>{" "}
                      <br />
                      <div className="form-check mx-1 form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          id="male"
                          value="Male"
                          checked={values.gender === "Male"}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="male">
                          Male
                        </label>
                      </div>
                      <div className="form-check mx-1 form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="gender"
                          id="female"
                          value="Female"
                          checked={values.gender === "Female"}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="female">
                          Female
                        </label>
                      </div>
                    </div>

                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="address">
                        Address
                        <span className="error">*</span>
                      </label>

                      <textarea
                        name="address"
                        id="address"
                        className="form-control"
                        cols="30"
                        rows="3"
                        onChange={handleChange}
                        value={values?.address}
                      ></textarea>
                      {touched?.address && errors?.address ? (
                        <div className="error">{errors?.address}</div>
                      ) : null}
                    </div>
                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="nationality">
                        Nationality
                      </label>
                      <input
                        type="text"
                        name="nationality"
                        className="form-control"
                        id="nationality"
                        onChange={handleChange}
                        value={values?.nationality}
                      />
                      {touched?.nationality && errors?.nationality ? (
                        <div className="error">{errors?.nationality}</div>
                      ) : null}
                    </div>
                    <div className="form-outline col-sm-6 flex-fill ">
                      <label className="form-label" htmlFor="firstlanguage">
                        First Language
                        <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        name="firstlanguage"
                        className="form-control"
                        id="firstlanguage"
                        onChange={handleChange}
                        value={values?.firstlanguage}
                      />
                      {touched?.firstlanguage && errors?.firstlanguage ? (
                        <div className="error">{errors?.firstlanguage}</div>
                      ) : null}
                    </div>
                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="otherlanguage">
                        Other Language Known
                      </label>
                      <input
                        type="text"
                        name="otherlanguage"
                        className="form-control"
                        id="otherlanguage"
                        onChange={handleChange}
                        value={values?.otherlanguage}
                      />
                      {touched?.otherlanguage && errors?.otherlanguage ? (
                        <div className="error">{errors?.otherlanguage}</div>
                      ) : null}
                    </div>

                    <h5 className="mt-5">Admission details</h5>
                    <hr />
                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="gr">
                        GR no.
                        <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        name="gr"
                        className="form-control"
                        id="gr"
                        onChange={handleChange}
                        value={values?.gr}
                        disabled
                      />
                      {touched?.gr && errors?.gr ? (
                        <div className="error">{errors?.gr}</div>
                      ) : null}
                    </div>
                    <div data-mdb-input-init className="form-outline mb-4 col-sm-6">
                      <label className="form-label" htmlFor="std">
                        Select standard
                        <span className="error">*</span>
                      </label>
                      <select
                        className="form-select"
                        name="standard"
                        id="standard"
                        onChange={handleChange}
                        value={values?.standard || ""}
                        aria-label="Default select example"
                      >
                        <option value="">
                            Select Standard
                          </option>
                        {getAllStandardMedium &&
                            getAllStandardMedium.standard &&
                            getAllStandardMedium.standard.map((std, index) => (
                              <option value={std} key={index}>
                                {std}
                              </option>
                            ))}
                      </select>
                    </div>
                    <div data-mdb-input-init className="form-outline mb-4 col-sm-6">
                      <label className="form-label" htmlFor="medium" id="medium">
                        Select medium
                        <span className="error">*</span>
                      </label>
                      <select
                        className="form-select"
                        placeholder="Select medium"
                        name="medium"
                        aria-label="Default select example"
                        onChange={handleChange}
                        value={values?.medium || ""}
                      >
                        <option value="">
                            Select Medium
                          </option>
                          {getAllStandardMedium &&
                            getAllStandardMedium.medium &&
                            getAllStandardMedium.medium.map((med, index) => (
                              <option value={med} key={index}>
                                {med}
                              </option>
                            ))}
                      </select>
                    </div>

                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="dateofadmission">
                        Admission Date
                        <span className="error">*</span>
                      </label>
                      <input
                        type="date"
                        name="dateofadmission"
                        className="form-control"
                        id="dateofadmission"
                        onChange={handleChange}
                        value={values?.dateofadmission}
                      />
                      {touched?.dateofadmission && errors?.dateofadmission ? (
                        <div className="error">{errors?.dateofadmission}</div>
                      ) : null}
                    </div>

                    <div data-mdb-input-init className="form-outline col-sm-6 mb-4">
                      <div className="row flex-row align-items-center mb-4">
                        <div className="form-outline col-sm-6 flex-fill">
                          <label className="form-label" htmlFor="fatherimage">
                            Student Image (.jpg, .jpeg, .png only) (Size between 1mb to 3mb)
                          </label>
                          <SingleImageUploder
                            singleSelectedImgs={studentimage}
                            setSingleImages={setStudentimage}
                            editDataImage={getSingleStudentData?.studentimage}
                          />
                        </div>
                      </div>
                      {touched?.studentimage && errors?.studentimage ? (
                        <div className="error">{errors?.studentimage}</div>
                      ) : null}
                    </div>

                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="totalfeesfromform">
                        Fees (Bargained)
                        <span className="error"></span>
                      </label>
                      <input
                        type="text"
                        name="totalfeesfromform"
                        className="form-control"
                        id="totalfeesfromform"
                        onChange={handleChange}
                        value={values?.totalfeesfromform}
                      />
                      {/* {touched?.totalfeesfromform && errors?.totalfeesfromform ? (
                        <div className="error">{errors?.totalfeesfromform}</div>
                      ) : null} */}
                    </div>

                    <h4>Father Details</h4>
                    <hr />

                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="fathername">
                        Full Name
                        <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        name="fathername"
                        className="form-control"
                        id="fathername"
                        onChange={handleChange}
                        value={values?.fathername}
                      />
                      {touched?.fathername && errors?.fathername ? (
                        <div className="error">{errors?.fathername}</div>
                      ) : null}
                    </div>
                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label dob" htmlFor="fatherdateofbirth">
                        Date Of Birth
                      </label>
                      <input
                        type="date"
                        name="fatherdateofbirth"
                        className="form-control form_input"
                        id="fatherdateofbirth"
                        onChange={handleChange}
                        value={values?.fatherdateofbirth}
                      />
                      {touched?.fatherdateofbirth && errors?.fatherdateofbirth ? (
                        <div className="error">{errors?.fatherdateofbirth}</div>
                      ) : null}
                    </div>


                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="fatherno">
                        Mobile no.
                        <span className="error">*</span>
                      </label>
                      <input
                        type="number"
                        name="fatherno"
                        className="form-control"
                        id="fatherno"
                        onChange={handleChange}
                        value={values?.fatherno}
                      />
                      {touched?.fatherno && errors?.fatherno ? (
                        <div className="error">{errors?.fatherno}</div>
                      ) : null}
                    </div>

                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="fathergmail">
                        Gmail
                      </label>
                      <input
                        type="email"
                        name="fathergmail"
                        className="form-control"
                        id="fathergmail"
                        onChange={handleChange}
                        value={values?.fathergmail}
                      />
                      {touched?.fathergmail && errors?.fathergmail ? (
                        <div className="error">{errors?.fathergmail}</div>
                      ) : null}
                    </div>
                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="fatherprofession">
                        Profession
                        <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        name="fatherprofession"
                        className="form-control"
                        id="fatherprofession"
                        onChange={handleChange}
                        value={values?.fatherprofession}
                      />
                      {touched?.fatherprofession && errors?.fatherprofession ? (
                        <div className="error">{errors?.fatherprofession}</div>
                      ) : null}
                    </div>
                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="fatherdesignation">
                        Designation
                      </label>
                      <input
                        type="text"
                        name="fatherdesignation"
                        className="form-control"
                        id="fatherdesignation"
                        onChange={handleChange}
                        value={values?.fatherdesignation}
                      />
                      {touched?.fatherdesignation &&
                        errors?.fatherdesignation ? (
                        <div className="error">{errors?.fatherdesignation}</div>
                      ) : null}
                    </div>

                    <div data-mdb-input-init className="form-outline col-sm-6 mb-4">
                      <div className="row flex-row align-items-center mb-4">
                        <div className="form-outline col-sm-6 flex-fill">
                          <label className="form-label" htmlFor="fatherimage">
                            Father Image (.jpg, .jpeg, .png only) (Size between 1mb to 3mb)
                          </label>
                          <SingleImageUploder
                            singleSelectedImgs={fatherimage}
                            setSingleImages={setFatherimage}
                            editDataImage={getSingleStudentData?.parent_details?.fatherimage}
                          />
                        </div>
                      </div>
                      {touched?.fatherimage && errors?.fatherimage ? (
                        <div className="error">{errors?.fatherimage}</div>
                      ) : null}
                    </div>
                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="fatherqualification">
                        Educational Qualification
                        <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        name="fatherqualification"
                        className="form-control"
                        id="fatherqualification"
                        onChange={handleChange}
                        value={values?.fatherqualification}
                      />
                      {touched?.fatherqualification &&
                        errors?.fatherqualification ? (
                        <div className="error">
                          {errors?.fatherqualification}
                        </div>
                      ) : null}
                    </div>

                    <h4>Mother Details</h4>
                    <hr />
                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="mothername">
                        Full Name
                        <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        name="mothername"
                        className="form-control"
                        id="mothername"
                        onChange={handleChange}
                        value={values?.mothername}
                      />
                      {touched?.mothername && errors?.mothername ? (
                        <div className="error">{errors?.mothername}</div>
                      ) : null}
                    </div>
                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label dob" htmlFor="motherdateofbirth">
                        Date Of Birth
                      </label>
                      <input
                        type="date"
                        name="motherdateofbirth"
                        className="form-control form_input"
                        id="motherdateofbirth"
                        onChange={handleChange}
                        value={values?.motherdateofbirth}
                      />
                      {touched?.motherdateofbirth && errors?.motherdateofbirth ? (
                        <div className="error">{errors?.motherdateofbirth}</div>
                      ) : null}
                    </div>

                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="motherno">
                        Mobile no.
                        <span className="error">*</span>
                      </label>
                      <input
                        type="number"
                        name="motherno"
                        className="form-control"
                        id="motherno"
                        onChange={handleChange}
                        value={values?.motherno}
                      />
                      {touched?.motherno && errors?.motherno ? (
                        <div className="error">{errors?.motherno}</div>
                      ) : null}
                    </div>

                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="mothergmail">
                        Gmail
                      </label>
                      <input
                        type="email"
                        name="mothergmail"
                        className="form-control"
                        id="mothergmail"
                        onChange={handleChange}
                        value={values?.mothergmail}
                      />
                      {touched?.mothergmail && errors?.mothergmail ? (
                        <div className="error">{errors?.mothergmail}</div>
                      ) : null}
                    </div>

                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="motherprofession">
                        Profession
                      </label>
                      <input
                        type="text"
                        name="motherprofession"
                        className="form-control"
                        id="motherprofession"
                        onChange={handleChange}
                        value={values?.motherprofession}
                      />
                      {touched?.motherprofession && errors?.motherprofession ? (
                        <div className="error">{errors?.motherprofession}</div>
                      ) : null}
                    </div>
                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="motherdesignation">
                        Designation
                      </label>
                      <input
                        type="text"
                        name="motherdesignation"
                        className="form-control"
                        id="motherdesignation"
                        onChange={handleChange}
                        value={values?.motherdesignation}
                      />
                      {touched?.motherdesignation &&
                        errors?.motherdesignation ? (
                        <div className="error">{errors?.motherdesignation}</div>
                      ) : null}
                    </div>
                    <div data-mdb-input-init className="form-outline col-sm-6 mb-4">
                      <div className="row flex-row align-items-center mb-4">
                        <div className="form-outline col-sm-6 flex-fill">
                          <label className="form-label" htmlFor="motherimage">
                            Mother Image (.jpg, .jpeg, .png only) (Size between 1mb to 3mb)
                          </label>
                          <SingleImageUploder
                            singleSelectedImgs={motherimage}
                            setSingleImages={setMotherimage}
                            editDataImage={getSingleStudentData?.parent_details?.motherimage}
                          />
                        </div>
                      </div>
                      {touched?.motherimage && errors?.motherimage
                        ? toast.error("Student Image is require")
                        : null}
                    </div>
                    <div className="form-outline col-sm-6 flex-fill">
                      <label className="form-label" htmlFor="motherqualification">
                        Educational Qualification
                      </label>
                      <input
                        type="text"
                        name="motherqualification"
                        className="form-control"
                        id="motherqualification"
                        onChange={handleChange}
                        value={values?.motherqualification}
                      />
                      {touched?.motherqualification &&
                        errors?.motherqualification ? (
                        <div className="error">
                          {errors?.motherqualification}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    {id ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-thmdanger mx-2"
                          onClick={() => navigate("/adminpanel/students")}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-thmdanger mx-2"
                          onClick={() => setOpenModal(false)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                    {id ? (
                      <>
                        <input
                          type="submit"
                          id="addstudent"
                          name="submit"
                          value="Update Student"
                          className="btn btn-thm"
                        />
                      </>
                    ) : (
                      <>
                        <input
                          type="submit"
                          id="addstudent"
                          name="submit"
                          value="Add Student"
                          className="btn btn-thm"
                        />
                      </>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStudent;
