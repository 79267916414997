// get multipe images
export default function selectedFiles(e) {
  if (e) {
    let store = [];
    const files = e.target.files;
    for (let x = 0; x < files.length; x++) {
      const fileObject = {
        file: files[x]
      };
      store.push(fileObject);
    }
    return store;
  }
  return false;
}

// size
