import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../Components/Sidebar";
import CustomModal from "../Components/Modal";
import AddSyllabus from "../Components/Syllabus/AddSyllabus";
import {
  DeleteTopic,
  GetAllStandardMedium,
  GetTopicFilterData,
  GetTopicList,
} from "../Features/properties/StudentService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader";
import { toast } from "react-toastify";
import DeleteModal from "../Components/DeleteModal";
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import { CSVLink } from "react-csv";
import Cookies from "js-cookie";
import ReactPaginate from "react-paginate";

const headers = [
  { label: "Title", key: "title" },
  { label: "Topic", key: "topic" },
  { label: "Standard", key: "standard" },
  { label: "Medium", key: "medium" },
];

const Syllabus = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShowDeleteModal, setShowDeleteModal] = useState(false);
  const [isIsId, setIsId] = useState(0);
  const [standard, setStandard] = useState("");
  const [medium, setMedium] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const topicPerPage = 10;
  const { getAllStandardMedium, getTopicList, loading } = useSelector((state) => state.students);

  useEffect(() => {
    const accessToken = Cookies.get("scholar-auth-token");
    if (!accessToken) {
      navigate("/adminpanel");
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (standard !== "" || medium !== "") {
      dispatch(GetTopicFilterData({ standard: standard, medium: medium }));
    } else {
      dispatch(GetTopicList());
    }
    dispatch(GetAllStandardMedium());
    setPageNumber(0); // Reset page number when filters are applied

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, standard, medium]);

  const columns = useMemo(
    () => [
      { Header: "No", accessor: (_, index) => index + 1 },
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ value }) => (
          <span className="text-wrap" data-bs-toggle="tooltip" title={value}>
            {value}
          </span>
        ),
      },
      {
        Header: "Topic",
        accessor: "topic",
        Cell: ({ value }) => (
          <span className="text-wrap" data-bs-toggle="tooltip" title={value}>
            {value}
          </span>
        ),
      },
      { Header: "Standard", accessor: "standard" },
      { Header: "Medium", accessor: "medium" },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <>
            <button
              className="btn btn-thmgreen mx-2"
              onClick={() =>
                navigate(`/adminpanel/editsyllabus?syllabusid=${row.original._id}`)
              }
            >
              EDIT
            </button>
            <button
              className="btn btn-thmdanger"
              onClick={(e) => handleOnDelete(e, row.original._id)}
            >
              DELETE
            </button>
          </>
        ),
      },
    ],
    [navigate]
  );

  const data = useMemo(() => getTopicList || [], [getTopicList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    state: { globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: pageNumber, pageSize: topicPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    gotoPage(pageNumber);
  }, [pageNumber, gotoPage]);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleGlobalFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setPageNumber(0); // Reset page number when global filter changes
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleAddTopic = () => {
    setOpenModal(true);
  };

  const handleOnDelete = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDeleteModal(true);
    setIsId(id);
  };

  const handleOnDeleteSave = async () => {
    const res = await dispatch(DeleteTopic(isIsId));
    if (res?.type === "students/DeleteTopic/fulfilled") {
      setShowDeleteModal(false);
      toast.success("Topic Deleted Successfully", {
        autoClose: 3000,
      });
      setIsId(0);
      await dispatch(GetTopicList());
    } else {
      toast.error("Something Went Wrong", {
        autoClose: 3000,
      });
    }
  };

  const handleStandardChange = (event) => {
    const selectedStandard = event.target.value;
    setStandard(selectedStandard);
    setPageNumber(0); // Reset page number when the standard filter changes
  };

  const handleMediumChange = (event) => {
    const selectedMedium = event.target.value;
    setMedium(selectedMedium);
    setPageNumber(0); // Reset page number when the medium filter changes
  };

  return (
    <>
      <Sidebar />
      <div className="home-section">
        {loading ? <Loader /> : <></>}
        <div className="container-fluid">
          <div className="row py-3 px-4">
            <div className="col-lg-12 mainpage-padding">
              <h1 className="mb-4">Syllabus</h1>
              <div>
                <div className="text-end">
                  <button className="btn btn-thm add-btn" onClick={() => handleAddTopic()}>
                    + Add Topic
                  </button>
                  <div className="d-flex justify-content-between row mb-3 mt-4">
                    <div className="d-md-flex col-md-6">
                      <div data-mdb-input-init className="form-outline filterfield">
                        <select
                          className="form-select filter-box"
                          name="standard"
                          id="standard"
                          onChange={handleStandardChange}
                          value={standard || ""}
                          aria-label="Default select example"
                        >
                          <option value="">Select Standard</option>
                          {getAllStandardMedium &&
                            getAllStandardMedium.standard &&
                            getAllStandardMedium.standard.map((std, index) => (
                              <option value={std} key={index}>
                                {std}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div data-mdb-input-init className="form-outline filterfield">
                        <select
                          className="form-select filter-box"
                          placeholder="Select medium"
                          name="medium"
                          aria-label="Default select example"
                          onChange={handleMediumChange}
                          value={medium || ""}
                        >
                          <option value="">Select Medium</option>
                          {getAllStandardMedium &&
                            getAllStandardMedium.medium &&
                            getAllStandardMedium.medium.map((med, index) => (
                              <option value={med} key={index}>
                                {med}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div data-mdb-input-init className="col-md-6 d-flex form-outline justify-content-md-end">
                      {/* Search Input Field */}
                      <input
                        type="search"
                        className="form-control filter-box"
                        placeholder="Search..."
                        value={globalFilter || ""}
                        onChange={handleGlobalFilterChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="table-responsive mb-5">
                  <table
                    {...getTableProps()}
                    className="auto-index table text-center table-striped display scroll-tab table-margin"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="table-primary">
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render("Header")}
                              {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map(row => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} className="align-middle">
                            {row.cells.map(cell => (
                              <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {getTopicList.length === 0 ? (
                    <h3 className="text-center text-secondary mt-5">No Students found</h3>
                  ) : null}
                  <div className="dt-export">
                    <CSVLink data={getTopicList} headers={headers} filename="Student_List.csv">
                      <button className="btn btn-thmgreen">
                        Export <span className="mx-1"></span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15px" fill="#ffffff" viewBox="0 0 384 512">
                          <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                        </svg>
                      </button>
                    </CSVLink>
                  </div>
                  <div className="dt-paging paging_full_numbers h-38px">
                    <ReactPaginate
                      previousLabel={<button className="btn btn-thm">{"<"}</button>}
                      nextLabel={<button className="btn btn-thm">{">"}</button>}
                      breakLabel={<button className="btn btn-thm">{"..."}</button>}
                      pageCount={Math.ceil(data.length / topicPerPage)}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link"}
                      renderOnZeroPageCount={null}
                      pageClassName={"page-item"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      breakClassName={"page-item"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        title={"Add Topic"}
        show={openModal}
        onClose={handleModalClose}
        modalSize="modal-md size-full"
      >
        <AddSyllabus setOpenModal={setOpenModal} />
      </CustomModal>
      <DeleteModal
        isShowModal={isShowDeleteModal}
        title="Delete Topic"
        handleOnDeleteSave={handleOnDeleteSave}
        setDeleteModal={setShowDeleteModal}
        deleteMsj={"Are you sure you want to delete this Topic?"}
      />
    </>
  );
};

export default Syllabus;
