import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Cookies from "js-cookie";
import DeleteModal from "./DeleteModal";

const Container = styled.div`
  position: fixed;
  z-index: 999;
`;

const SidebarContainer = styled.div`
  background-color: #181d3d;
  width: 6rem;
  height: 95vh;
  margin-top: 1rem;
  border-radius: 0 30px 30px 0;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  text-wrap: nowrap;
  min-height: 600px;
`;

const Logo = styled.div`
  width: 2rem;
  color: var(--white);
  svg {
    width: 100%;
    height: auto;
  }
`;

const SlickBar = styled.ul`
  color: var(--white);
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #181d3d;
  padding: 1rem 0rem;
  position: absolute;
  top: 5.9rem;
  left: 0;
  width: ${(props) => (props.clicked ? "15.4rem" : "5.8rem")};
  transition: all 0.3s ease;
  border-radius: 0 30px 30px 0;
  margin-top: 3px;
`;

const Item = styled(Link)`
  text-decoration: none;
  color: var(--white);
  width: 100%;
  padding: 10px 0;
  cursor: pointer;
  display: flex;
  padding-left: 2rem;
  background-color: ${(props) => (props.isActive ? "#1e244d" : "inherit")};
  border-right: ${(props) => (props.isActive ? "4px solid var(--white)" : "none")};
  svg {
    filter: ${(props) =>
    props.isActive
      ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)"
      : "invert(92%) sepia(4%) saturate(1033%) hue-rotate(169deg) brightness(78%) contrast(85%)"};
  }
  &:hover {
    svg {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(103%) contrast(103%);
    }
  }
`;

const Text = styled.span`
  width: ${(props) => (props.clicked ? "70%" : "0")};
  overflow: hidden;
  margin-left: ${(props) => (props.clicked ? "2rem" : "0")};
  transition: all 0.3s ease;
`;

const Sidebar = () => {
  const [click, setClick] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isShowConfirmModal, setShowConfirmModal] = useState(false);

  const handleLogout = (e) => {
    e.preventDefault();
    setShowConfirmModal(true);
  };

  const confirmLogout = () => {
    Cookies.remove("scholar-auth-token");
    navigate("/adminpanel");
  };

  const handleMouseEnter = () => setClick(true);
  const handleMouseLeave = () => setClick(false);
  const isActive = (route) => location.pathname === route;

  return (
    <>
      <div className='d-md-block d-none'>
        <div className="sibar-div" onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <Container>
            <SidebarContainer>
              <Logo>
                <img
                  src="https://shreescholarstarnursery.in/adminpanel/static/media/logo-login.png"
                  alt="school logo"
                  width="65"
                  height="65"
                />
              </Logo>
              <SlickBar clicked={click}>
                <Item
                  className="sidebar-item"
                  onClick={() => setClick(false)}
                  to="/adminpanel/dashboard"
                  isActive={isActive("/adminpanel/dashboard")}
                >
                  <Link to={"/adminpanel/dashboard"}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" width="100" viewBox="0 0 576 512"><path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" /></svg>
                  </Link>
                  <Text clicked={click}>
                    <Link to={"/adminpanel/dashboard"}>Dashboard</Link>
                  </Text>
                </Item>
                <Item
                  className="sidebar-item"
                  onClick={() => setClick(false)}
                  to="/adminpanel/students"
                  isActive={isActive("/adminpanel/students")}
                >
                  <Link to={"/adminpanel/students"}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 640 512" width="100"><path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" /></svg>
                  </Link>
                  <Text clicked={click}>
                    <Link to={"/adminpanel/students"}>Student Details</Link>
                  </Text>
                </Item>
                <Item
                  className="sidebar-item"
                  onClick={() => setClick(false)}
                  to="/adminpanel/fees"
                  isActive={isActive("/adminpanel/fees")}
                >
                  <Link to={"/adminpanel/fees"}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 640 512" width="100"><path d="M96 96V320c0 35.3 28.7 64 64 64H576c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160c-35.3 0-64 28.7-64 64zm64 160c35.3 0 64 28.7 64 64H160V256zM224 96c0 35.3-28.7 64-64 64V96h64zM576 256v64H512c0-35.3 28.7-64 64-64zM512 96h64v64c-35.3 0-64-28.7-64-64zM288 208a80 80 0 1 1 160 0 80 80 0 1 1 -160 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V360c0 66.3 53.7 120 120 120H520c13.3 0 24-10.7 24-24s-10.7-24-24-24H120c-39.8 0-72-32.2-72-72V120z" /></svg>
                  </Link>
                  <Text clicked={click}>
                    <Link to={"/adminpanel/fees"}>Student Fee</Link>
                  </Text>
                </Item>
                <Item
                  className="sidebar-item"
                  onClick={() => setClick(false)}
                  to="/adminpanel/syllabus"
                  isActive={isActive("/adminpanel/syllabus")}
                >
                  <Link to={"/adminpanel/syllabus"}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 576 512" width="100"><path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" /></svg>
                  </Link>
                  <Text clicked={click}>
                    <Link to={"/adminpanel/syllabus"}>Syllabus</Link>
                  </Text>
                </Item>
                <Item
                  className="sidebar-item"
                  onClick={() => setClick(false)}
                  to="/adminpanel/parentsdetail"
                  isActive={isActive("/adminpanel/parentsdetail")}
                >
                  <Link to={"/adminpanel/parentsdetail"}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 576 512" width="100"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z" /></svg>
                  </Link>
                  <Text clicked={click}>
                    <Link to={"/adminpanel/parentsdetail"}>Parents Details</Link>
                  </Text>
                </Item>
                <Item
                  className="sidebar-item"
                  onClick={() => setClick(false)}
                  to="/adminpanel/feestructure"
                  isActive={isActive("/adminpanel/feestructure")}
                >
                  <Link to={"/adminpanel/feestructure"}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 512 512"><path d="M64 256V160H224v96H64zm0 64H224v96H64V320zm224 96V320H448v96H288zM448 256H288V160H448v96zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" /></svg>
                  </Link>
                  <Text clicked={click}>
                    <Link to={"/adminpanel/feestructure"}>Fee Structure</Link>
                  </Text>
                </Item>
                <Item
                  className="sidebar-item"
                  onClick={() => setClick(false)}
                  to="/adminpanel/notice"
                  isActive={isActive("/adminpanel/notice")}
                >
                  <Link to={"/adminpanel/notice"}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 512 512"><path d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z" /></svg>
                  </Link>
                  <Text clicked={click}>
                    <Link to={"/adminpanel/notice"}>Notice</Link>
                  </Text>
                </Item>
                <Item
                  className="sidebar-item"
                  onClick={() => setClick(false)}
                  to="/adminpanel/teacher"
                  isActive={isActive("/adminpanel/teacher")}
                >
                  <Link to={"/adminpanel/teacher"}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 640 512"><path d="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H384V320c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v32h64V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm0 64a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM133.3 352h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z" /></svg>
                  </Link>
                  <Text clicked={click}>
                    <Link to={"/adminpanel/teacher"}>Teachers Details</Link>
                  </Text>
                </Item>
                <Item
                  className="sidebar-item"
                  onClick={() => setClick(false)}
                  to="/adminpanel/slider"
                  isActive={isActive("/adminpanel/slider")}
                >
                  <Link to={"/adminpanel/slider"}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 576 512"><path d="M160 32c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160zM396 138.7l96 144c4.9 7.4 5.4 16.8 1.2 24.6S480.9 320 472 320H328 280 200c-9.2 0-17.6-5.3-21.6-13.6s-2.9-18.2 2.9-25.4l64-80c4.6-5.7 11.4-9 18.7-9s14.2 3.3 18.7 9l17.3 21.6 56-84C360.5 132 368 128 376 128s15.5 4 20 10.7zM192 128a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V344c0 75.1 60.9 136 136 136H456c13.3 0 24-10.7 24-24s-10.7-24-24-24H136c-48.6 0-88-39.4-88-88V120z" /></svg>
                  </Link>
                  <Text clicked={click}>
                    <Link to={"/adminpanel/slider"}>slider Details</Link>
                  </Text>
                </Item>
                <Item
                  className="sidebar-item"
                  onClick={(e) => { setClick(false); handleLogout(e); }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" /></svg>
                  <Text clicked={click}>
                    Logout
                  </Text>
                </Item>
                {/* Other menu items */}
              </SlickBar>
            </SidebarContainer>
          </Container>
        </div>
      </div>

      <div className='d-md-none d-block mobile-navbar sticky-top'>
        <div className="position-relative d-inline-block sidebar-animation">
          <div className='my-2 mx-3'>
            <button className="btn btn-thm">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="18"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" /></svg>
            </button>
          </div>

          <div className='position-absolute-for-sidenav position-absolute'>
            <div className="sibar-div" onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}>
              <Container>
                <SidebarContainer>
                  <Logo>
                    <img
                      src="https://shreescholarstarnursery.in/adminpanel/static/media/logo-login.png"
                      alt="school logo"
                      width="65"
                      height="65"
                    />
                  </Logo>
                  <SlickBar clicked={click}>
                    <Item
                      className="sidebar-item"
                      onClick={() => setClick(false)}
                      to="/adminpanel/dashboard"
                      isActive={isActive("/adminpanel/dashboard")}
                    >
                      <Link to={"/adminpanel/dashboard"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" width="100" viewBox="0 0 576 512"><path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" /></svg>
                      </Link>
                      <Text clicked={click}>
                        <Link to={"/adminpanel/dashboard"}>Dashboard</Link>
                      </Text>
                    </Item>

                    <Item
                      className="sidebar-item"
                      onClick={() => setClick(false)}
                      to="/adminpanel/students"
                      isActive={isActive("/adminpanel/students")}
                    >
                      <Link to={"/adminpanel/students"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 640 512" width="100"><path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" /></svg>
                      </Link>
                      <Text clicked={click}>
                        <Link to={"/adminpanel/students"}>Student Details</Link>
                      </Text>
                    </Item>

                    <Item
                      className="sidebar-item"
                      onClick={() => setClick(false)}
                      to="/adminpanel/fees"
                      isActive={isActive("/adminpanel/fees")}
                    >
                      <Link to={"/adminpanel/fees"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 640 512" width="100"><path d="M96 96V320c0 35.3 28.7 64 64 64H576c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160c-35.3 0-64 28.7-64 64zm64 160c35.3 0 64 28.7 64 64H160V256zM224 96c0 35.3-28.7 64-64 64V96h64zM576 256v64H512c0-35.3 28.7-64 64-64zM512 96h64v64c-35.3 0-64-28.7-64-64zM288 208a80 80 0 1 1 160 0 80 80 0 1 1 -160 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V360c0 66.3 53.7 120 120 120H520c13.3 0 24-10.7 24-24s-10.7-24-24-24H120c-39.8 0-72-32.2-72-72V120z" /></svg>
                      </Link>
                      <Text clicked={click}>
                        <Link to={"/adminpanel/fees"}>Student Fee</Link>
                      </Text>
                    </Item>

                    <Item
                      className="sidebar-item"
                      onClick={() => setClick(false)}
                      to="/adminpanel/syllabus"
                      isActive={isActive("/adminpanel/syllabus")}
                    >
                      <Link to={"/adminpanel/syllabus"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 576 512" width="100"><path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" /></svg>
                      </Link>
                      <Text clicked={click}>
                        <Link to={"/adminpanel/syllabus"}>Syllabus</Link>
                      </Text>
                    </Item>

                    <Item
                      className="sidebar-item"
                      onClick={() => setClick(false)}
                      to="/adminpanel/parentsdetail"
                      isActive={isActive("/adminpanel/parentsdetail")}
                    >
                      <Link to={"/adminpanel/parentsdetail"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 576 512" width="100"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z" /></svg>
                      </Link>
                      <Text clicked={click}>
                        <Link to={"/adminpanel/parentsdetail"}>Parents Details</Link>
                      </Text>
                    </Item>

                    <Item
                      className="sidebar-item"
                      onClick={() => setClick(false)}
                      to="/adminpanel/feestructure"
                      isActive={isActive("/adminpanel/feestructure")}
                    >
                      <Link to={"/adminpanel/feestructure"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 512 512"><path d="M64 256V160H224v96H64zm0 64H224v96H64V320zm224 96V320H448v96H288zM448 256H288V160H448v96zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" /></svg>
                      </Link>
                      <Text clicked={click}>
                        <Link to={"/adminpanel/feestructure"}>Fee Structure</Link>
                      </Text>
                    </Item>

                    <Item
                      className="sidebar-item"
                      onClick={() => setClick(false)}
                      to="/adminpanel/notice"
                      isActive={isActive("/adminpanel/notice")}
                    >
                      <Link to={"/adminpanel/notice"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 512 512"><path d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z" /></svg>
                      </Link>
                      <Text clicked={click}>
                        <Link to={"/adminpanel/notice"}>Notice</Link>
                      </Text>
                    </Item>

                    <Item
                      className="sidebar-item"
                      onClick={() => setClick(false)}
                      to="/adminpanel/teacher"
                      isActive={isActive("/adminpanel/teacher")}
                    >
                      <Link to={"/adminpanel/teacher"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 640 512"><path d="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H384V320c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v32h64V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm0 64a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM133.3 352h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z" /></svg>
                      </Link>
                      <Text clicked={click}>
                        <Link to={"/adminpanel/teacher"}>Teachers Details</Link>
                      </Text>
                    </Item>

                    <Item
                      className="sidebar-item"
                      onClick={() => setClick(false)}
                      to="/adminpanel/slider"
                      isActive={isActive("/adminpanel/slider")}
                    >
                      <Link to={"/adminpanel/slider"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 576 512"><path d="M160 32c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160zM396 138.7l96 144c4.9 7.4 5.4 16.8 1.2 24.6S480.9 320 472 320H328 280 200c-9.2 0-17.6-5.3-21.6-13.6s-2.9-18.2 2.9-25.4l64-80c4.6-5.7 11.4-9 18.7-9s14.2 3.3 18.7 9l17.3 21.6 56-84C360.5 132 368 128 376 128s15.5 4 20 10.7zM192 128a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V344c0 75.1 60.9 136 136 136H456c13.3 0 24-10.7 24-24s-10.7-24-24-24H136c-48.6 0-88-39.4-88-88V120z" /></svg>
                      </Link>
                      <Text clicked={click}>
                        <Link to={"/adminpanel/slider"}>slider Details</Link>
                      </Text>
                    </Item>

                    <Item
                      className="sidebar-item"
                      onClick={(e) => { setClick(false); handleLogout(e); }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" /></svg>
                      <Text clicked={click}>
                        Logout
                      </Text>
                    </Item>

                    {/* Other menu items */}
                  </SlickBar>
                </SidebarContainer>
              </Container>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        isShowModal={isShowConfirmModal}
        title="Logout"
        handleOnDeleteSave={confirmLogout}
        setDeleteModal={setShowConfirmModal}
        deleteMsj={"Are you sure you want Logout?"}
      />
    </>
  );
};

export default Sidebar;
