import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeletePayment,
  GetSingleStudentData,
  GetStudentFilterData,
  MakePayment,
  UpdatePayment,
  removeStudentRecord,
} from "../../Features/properties/StudentService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { useTable, useSortBy, usePagination } from "react-table";
import ReactPaginate from "react-paginate";
import moment from "moment";
import DeleteModal from "../DeleteModal";
import CustomModal from "../Modal";
import { Form, Formik } from "formik";
import Autocomplete from '@mui/material/Autocomplete';


const FeesPayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShowDeleteModal, setShowDeleteModal] = useState(false);
  const [isShowEditModal, setShowEditModal] = useState(false);
  const [totalFee, setTotalFee] = useState("");
  const [mobilenumber, setMobilenumber] = useState("");
  const [pendingFee, setPendingFee] = useState("");
  const [paidFee, setPaidFee] = useState("");
  const { getStudentList, getSingleStudentData, loading } =
    useSelector((state) => state.students);
  const [Amount, setAmount] = useState("");
  const [studId, setStudId] = useState("");
  const [isIsId, setIsId] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [refresh, setRefresh] = useState(false); // Add this state
  const [studentData, setStudentData] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [selectStudent, setSelectStudent] = useState(null);
  const paymentsPerPage = 10;
  const standard = "";
  const medium = "";

  useEffect(() => {
    dispatch (removeStudentRecord());
  }, [])

  useEffect(() => {
    setStudentData(getSingleStudentData);
  }, [getSingleStudentData]);

  useEffect(() => {
    dispatch(GetStudentFilterData({ standard, medium }));
  }, [dispatch]);

  useEffect(() => {
    if (selectStudent) {
      const student = getStudentList.find(
        (student) => student.studentname === selectStudent
      );
      const id = student ? student._id : null;
      setStudId(id);
      if (id) {
        dispatch(GetSingleStudentData(id));
      }
    }
  }, [selectStudent]);

  useEffect(() => {
    setMobilenumber(getSingleStudentData?.parent_details?.fatherno);

    setTotalFee(
      getSingleStudentData?.totalfeesfromform !== ""
        ? getSingleStudentData?.totalfeesfromform
        : getSingleStudentData?.totalFees
    );
    setPendingFee(

      parseInt(getSingleStudentData?.payment_details.length === 0
        ? getSingleStudentData?.totalfeesfromform !== ""
          ? getSingleStudentData?.totalfeesfromform
          : getSingleStudentData?.totalFees
        : parseInt(getSingleStudentData?.totalfeesfromform !== ""
          ? getSingleStudentData?.totalfeesfromform
          : getSingleStudentData?.totalFees) -
        parseInt(getSingleStudentData?.payment_details.map(payment => parseFloat(payment.payingfees))
          .reduce((acc, curr) => acc + curr, 0)))
    );
    setPaidFee(
      parseInt(getSingleStudentData?.payment_details.map(payment => parseFloat(payment.payingfees))
        .reduce((acc, curr) => acc + curr, 0))
    );
  }, [getSingleStudentData]);

  useEffect(() => {
    if (parseInt(pendingFee) === 0) {
      toast.success("All your fees have been paid", { autoClose: 3000 });
    }
  }, [pendingFee]);

  useEffect(() => {
    if (studId) {
      dispatch(GetSingleStudentData(studId));
    }
  }, [studId, dispatch, refresh]); // Add refresh dependency

  const handleAmount = (event) => {
    const newValue = event.target.value;
    setAmount(newValue);
  };

  const makePayment = async () => {
    if (studId) {
      if (Amount) {
        if (parseInt(Amount) > parseInt(pendingFee)) {
          toast.error("Enter Valid Payment Amount", { autoClose: 3000 });
        } else {
          const res = await dispatch(
            MakePayment({
              studentid: studId,
              totalfees: totalFee,
              pendingfees: String(parseInt(pendingFee) - parseInt(Amount)),
              payingfees: Amount,
            })
          );
          if (res.type === "students/MakePayment/fulfilled") {
            if (parseInt(Amount) === parseInt(pendingFee)) {
              toast.success("All your fees have been paid", { autoClose: 3000 });
            } else {
              toast.success("Payment Successfull", { autoClose: 3000 });
            }
            const LastReceipt = localStorage.getItem("currentRnumber")
              ? localStorage.getItem("currentRnumber")
              : "0";
            localStorage.setItem("currentRnumber", parseInt(LastReceipt) + 1);
            setTotalFee("");
            setPendingFee("");
            setPaidFee("");
            setAmount("");
            setMobilenumber("");
            setRefresh(!refresh); // Trigger refresh
          }
        }
      } else {
        toast.error("Please Enter Amount", { autoClose: 3000 });
      }
    } else {
      toast.error("Please Select Student", { autoClose: 3000 });
    }

  };

  const handleDeletePayment = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDeleteModal(true);
    setIsId(id);
  };

  const handleEditPayment = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(studentData);
    const singlepaymentdata = studentData?.payment_details.find(payment => payment?._id === id);
    setPaymentData(singlepaymentdata);
    setShowEditModal(true);

  }

  const handleOnDeleteSave = async () => {
    const res = await dispatch(DeletePayment(isIsId));
    if (res?.type === "students/DeletePayment/fulfilled") {
      setShowDeleteModal(false);
      toast.success("Payment Deleted Successfully", {
        autoClose: 3000,
      });
      setIsId(0);
      if (studId) {
        await dispatch(GetSingleStudentData(studId));
      }
    } else {
      toast.error("Something Went Wrong", {
        autoClose: 3000,
      });
    }
  };

  const data = React.useMemo(
    () => (
      getSingleStudentData?.payment_details ? getSingleStudentData?.payment_details.slice().reverse() : []
    ),
    [getSingleStudentData?.payment_details]
  );


  const columns = React.useMemo(
    () => [
      {
        Header: "No",
        accessor: (row, index) => index + 1,
      },
      {
        Header: "Receipt no",
        accessor: "receiptno",
      },
      {
        Header: "Payment date",
        accessor: "created",
        Cell: ({ value }) => moment(value).format("DD-MM-YYYY"),
      },
      // { Header: "Total Fee", accessor: "totalfees" },
      // {
      //   Header: "Pending Fee",
      //   accessor: row => row.totalfees - row.payingfees,
      // },
      { Header: "Paid amount", accessor: "payingfees" },
      {
        Header: "Action",
        accessor: (row, index) => ({
          id: row._id,
          index: index,
        }),
        Cell: ({ value }) => (
          <div>

            {value.index === 0 ? ( // Check if it's the last row
              <>
                <button
                  className="btn btn-thmgreen mx-2"
                  onClick={(e) => {
                    handleEditPayment(e, value.id);
                  }}
                >
                  Edit
                </button>
                <button
                  className="btn btn-thmdanger mx-2"
                  onClick={(e) => {
                    handleDeletePayment(e, value.id);
                  }}
                >
                  Delete
                </button>
              </>

            ) : (
              <>
                <button
                  className="btn btn-thmgreendisable mx-2"
                  disabled // Disable delete button for non-last rows
                >
                  Edit
                </button>
                <button
                  className="btn btn-thmdangerdisable mx-2"
                  disabled // Disable delete button for non-last rows
                >
                  Delete
                </button>
              </>

            )}
            <button
              className="btn btn-thm mx-2"
              onClick={() => {
                navigate(
                  `/adminpanel/receipt?paymentid=${value.id}&studid=${value.studentidfp}`
                );
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="22"
              >
                <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
              </svg>
            </button>
          </div>
        ),
      },
    ],
    [navigate, data]
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: pageNumber, pageSize: paymentsPerPage },
    },
    useSortBy, // Enable sorting
    usePagination // Enable pagination
  );

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    gotoPage(selected);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  }

  return (
    <>
      {loading ? <Loader /> : <></>}
      <div className="d-flex justify-content-center">
        <form>
          <div className="col-md-12 mainpage-padding">
            <div data-mdb-input-init className="form-outline mb-4">
              <label htmlFor="totalfee">Select Student</label>
              <br />
              <Autocomplete
                id="free-solo-2-demo"
                options={getStudentList.map((option) => option.studentname)}
                value={selectStudent}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setSelectStudent(newValue);
                  } else {
                    setSelectStudent(newValue);
                  }
                }}
                renderInput={(params) => (
                  <div ref={params.InputProps.ref}>
                    <input
                      {...params.inputProps}
                      className="form-control fee-form-field"
                      type="search"
                    />
                  </div>
                )}
              />
            </div>
          </div>

          <div className="col-md-12 mainpage-padding">
            <div data-mdb-input-init className="form-outline mb-4">
              <label htmlFor="fee">Mobile Number</label>
              <br />
              <input
                type="text"
                name="fee"
                id="fee"
                className="form-control fee-form-field"
                value={mobilenumber}
                disabled
              />
            </div>
            <div data-mdb-input-init className="form-outline mb-4">
              <label htmlFor="fee">Enter Amount</label>
              <br />
              <input
                type="number"
                name="fee"
                id="fee"
                className="form-control fee-form-field"
                value={Amount}
                onChange={handleAmount}
              />
            </div>
          </div>

          <div className="col-md-12">
            <div data-mdb-input-init className="form-outline mb-4">
              <label htmlFor="totalfee">Total Fee</label>
              <br />
              <input
                type="text"
                name="totalfee"
                id="totalfee"
                className="form-control fee-form-field"
                value={totalFee}
                disabled
              />
            </div>
            <div data-mdb-input-init className="form-outline mb-4">
              <label htmlFor="pendingfee">Pending Fee</label>
              <br />
              <input
                type="text"
                name="pendingfee"

                id="pendingfee"
                className="form-control fee-form-field"
                value={isNaN(pendingFee) ? "" : pendingFee}
                disabled
              />
            </div>
            <div data-mdb-input-init className="form-outline mb-4">
              <label htmlFor="paidfee">Total Paid Fee</label>
              <br />
              <input
                type="text"
                name="paidfee"
                id="paidfee"
                className="form-control fee-form-field"
                value={isNaN(paidFee) ? "" : paidFee}
                disabled
              />
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <button
              type="reset"
              className="btn btn-thm mx-2"
              onClick={() => makePayment()}
            >
              Proceed To Pay
            </button>
          </div>
        </form>
      </div>

      <div className="mt-4">
        <h3>Fee History</h3>
        <div className="table-responsive mb-5">
          <table
            {...getTableProps()}
            className="auto-index table text-center table-striped display scroll-tab table-margin"
            style={{ width: "100%" }}
          >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className="table-primary">
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="align-middle">
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {getSingleStudentData && getSingleStudentData?.payment_details && getSingleStudentData?.payment_details.length === 0 ? (
            <h3 className="text-center text-secondary mt-5">
              No Payment Found
            </h3>
          ) : null}
        </div>
        <div className="dt-paging paging_full_numbers h-38px">
          <ReactPaginate
            previousLabel={<button className="btn btn-thm">{"<"}</button>}
            nextLabel={<button className="btn btn-thm">{">"}</button>}
            breakLabel={<button className="btn btn-thm">{"..."}</button>}
            pageCount={Math.ceil(data.length / paymentsPerPage)}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageLinkClassName={"page-link"}
            renderOnZeroPageCount={null}
            pageClassName={"page-item"}
            previousClassName={"page-item"}
            nextClassName={"page-item"}
            breakClassName={"page-item"}
          />
        </div>
      </div>
      <DeleteModal
        isShowModal={isShowDeleteModal}
        title={`Delete Payment`}
        handleOnDeleteSave={handleOnDeleteSave}
        setDeleteModal={setShowDeleteModal}
        deleteMsj={`Are you sure you want to delete this Payment?`}
      />
      <CustomModal
        title={"Update Payment"}
        show={isShowEditModal}
        onClose={handleEditModalClose}
        modalSize="modal-md size-full"
      >
        {/* <AddStudent setOpenModal={setOpenModal} /> */}
        <Formik
          initialValues={{
            receiptno: paymentData?.receiptno || "",
            payingfees: paymentData?.payingfees || "",
          }}
          enableReinitialize={true}
          onSubmit={async (values) => {
            let data = {
              ...values,
              studentidfp: paymentData?.studentidfp,
            };
            console.log(data, "askdhakshdkkhaksdkakjsdkasjdkahsdkjhaksjhdkahsdk");
            const res = await dispatch(UpdatePayment({ id: paymentData?._id, data: data }));

            if (res?.type === "students/UpdatePayment/fulfilled") {
              handleEditModalClose();
              toast.success("Payment Updated Successfully", {
                autoClose: 3000,
              });
              setRefresh(!refresh);
            } else {
              toast.error("Something Went Wrong", {
                autoClose: 3000,
              });
            }
          }}
        >
          {({ values, errors, touched, setFieldValue, handleChange }) => (
            <Form>
              <div className="row p-2">
                <div className="form-outline col flex-fill">
                  <label className="form-label" htmlFor="receiptno">
                    Receipt No
                    <span className="error">*</span>
                  </label>
                  <input
                    type="text"
                    name="receiptno"
                    className="form-control"
                    id="receiptno"
                    onChange={handleChange}
                    value={values?.receiptno}
                    disabled
                  />
                  {touched?.receiptno && errors?.receiptno ? (
                    <div className="error">{errors?.receiptno}</div>
                  ) : null}
                </div>
                <div className="form-outline col flex-fill">
                  <label className="form-label" htmlFor="payingfees">
                    Amount
                    <span className="error">*</span>
                  </label>
                  <input
                    type="text"
                    name="payingfees"
                    className="form-control"
                    id="payingfees"
                    onChange={handleChange}
                    value={values?.payingfees}
                  />
                  {touched?.payingfees && errors?.payingfees ? (
                    <div className="error">{errors?.payingfees}</div>
                  ) : null}
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-thmdanger mx-2"
                  onClick={() => handleEditModalClose()}
                >
                  Cancel
                </button>
                <input
                  type="submit"
                  id="addstudent"
                  name="submit"
                  value="Update Notice"
                  className="btn btn-thm"
                />
              </div>
            </Form>
          )}
        </Formik>

      </CustomModal>
    </>
  );
};

export default FeesPayment;
