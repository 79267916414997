import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AddSliderRecord,
  GetAllSliderList,
  GetSingleSliderData,
  UpdateSlider,
} from "../../Features/properties/StudentService";
import Loader from "../Loader";
import { Form, Formik } from "formik";
import SingleImageUploder from "../SingleImageUploder";
import { toast } from "react-toastify";

const AddSlider = ({ setOpenModal, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sliderImage, setSliderImage] = useState([]);
  const { getSingleSliderData, loading } = useSelector(
    (state) => state.students
  );

  useEffect(() => {
    if (id) {
      dispatch(GetSingleSliderData(id));
    }
  }, [id, dispatch]);

  const validate = (values) => {
    const errors = {};

    if (!values.socialimedia) {
      errors.socialimedia = "This field is required";
    }
    if (!values.socialimedialink) {
      errors.socialimedialink = "This field is required";
    }
    if (!id) {
      if (!sliderImage.length) {
        errors.sliderImage = "Image is Require";
      }
    }

    return errors;
  };

  return (
    <>
      <div className="col-lg-12">
        {loading ? <Loader /> : <></>}
        <div className="my_dashboard_review">
          <div className="row mb20">
            <Formik
              initialValues={{
                socialimedia: id ? getSingleSliderData?.socialimedia : "" || "",
                socialimedialink: id
                  ? getSingleSliderData?.socialimedialink
                  : "" || "",
              }}
              validate={validate}
              enableReinitialize={true}
              onSubmit={async (values) => {
                let data = {};
                if (id) {
                  data = {
                    ...values,
                    socialimage: sliderImage.length ? sliderImage[0]?.file : "",
                    old_socialimage: getSingleSliderData?.socialimage,
                  };
                } else {
                  data = {
                    ...values,
                    socialimage: sliderImage.length ? sliderImage[0]?.file : "",
                  };
                }
                let res;
                if (id) {
                  res = await dispatch(UpdateSlider({ id: id, data: data }));
                } else {
                  res = await dispatch(AddSliderRecord(data));
                }

                if (res?.type === "students/AddSliderRecord/fulfilled") {
                  toast.success("Slider Inserted Successfully", {
                    autoClose: 3000,
                  });
                  await dispatch(GetAllSliderList());
                  setOpenModal(false);
                } else if (res?.type === "students/UpdateSlider/fulfilled") {
                  toast.success("Slider Updated Successfully", {
                    autoClose: 3000,
                  });
                  await dispatch(GetAllSliderList());
                  return navigate("/adminpanel/slider");
                } else {
                  toast.error("Something Went Wrong", {
                    autoClose: 3000,
                  });
                }
              }}
            >
              {({ values, errors, touched, setFieldValue, handleChange }) => (
                <Form>
                  <div className="row p-2">
                    <div className="form-outline col flex-fill">
                      <label className="form-label" for="socialimedia">
                        Platform
                        <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        name="socialimedia"
                        className="form-control"
                        id="socialimedia"
                        onChange={handleChange}
                        value={values?.socialimedia}
                      />
                      {touched?.socialimedia && errors?.socialimedia ? (
                        <div className="error">{errors?.socialimedia}</div>
                      ) : null}
                    </div>
                    <div className="form-outline col flex-fill">
                      <label className="form-label" for="socialimedialink">
                        Social Media Link
                        <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        name="socialimedialink"
                        className="form-control"
                        id="socialimedialink"
                        onChange={handleChange}
                        value={values?.socialimedialink}
                      />
                      {touched?.socialimedialink && errors?.socialimedialink ? (
                        <div className="error">{errors?.socialimedialink}</div>
                      ) : null}
                    </div>

                    <div data-mdb-input-init className="form-outline col mb-4">
                      <div className="row flex-row align-items-center mb-4">
                        <div className="form-outline col-6 flex-fill">
                          <label className="form-label" for="fatherimage">
                            Slider Image (Size between 100kb to 500kb)
                            <span className="error">*</span>
                          </label>
                          <SingleImageUploder
                            singleSelectedImgs={sliderImage}
                            setSingleImages={setSliderImage}
                            editDataImage={getSingleSliderData?.socialimage}
                          />
                        </div>
                        {touched?.sliderImage && errors?.sliderImage ? (
                          <div className="error">{errors?.sliderImage}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    {id ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-thmdanger mx-2"
                          onClick={() => navigate("/adminpanel/slider")}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-thmdanger mx-2"
                          onClick={() => setOpenModal(false)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                    {id ? (
                      <>
                        <input
                          type="submit"
                          id="addstudent"
                          name="submit"
                          value="Update Slider"
                          className="btn btn-thm"
                        />
                      </>
                    ) : (
                      <>
                        <input
                          type="submit"
                          id="addstudent"
                          name="submit"
                          value="Add Slider"
                          className="btn btn-thm"
                        />
                      </>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSlider;
