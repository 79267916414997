import React, { useEffect } from 'react'
import Sidebar from '../Components/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { DashboardData } from '../Features/properties/StudentService';
import Loader from '../Components/Loader';

export const Dashboard = () => {
    const dispatch = useDispatch();
    const {getDashboardData, loading} = useSelector((state) => state.students);

    useEffect(() => {
        dispatch(DashboardData());
    }, [dispatch])

    console.log(getDashboardData, "+++++++++++++++++");

    return (
        <>
            <Sidebar />
            <div className="home-section">
                {loading ? <Loader /> : <></>}
                <div className="container-fluid">
                    <div className="row py-3 page_padding">
                        <h1 className="mx-2">Dashboard</h1>
                        <div className="row mt-4">
                            <div className="col-md-6 mb-4">
                                <div className="rounded rounded-3 shadow-lg m-2 p-3">
                                    <h1>Student Details</h1>
                                    <div className="d-flex justify-content-between mt-5">
                                        <div className="symbole"> <svg xmlns="http://www.w3.org/2000/svg" height="35" width="35" className="sidebar-svg" viewBox="0 0 640 512"><path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" /></svg></div>
                                        <div className="count">
                                            <h3>{getDashboardData?.studentCount}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <div className="rounded rounded-3 shadow-lg m-2 p-3">
                                    <h1>Parent Details</h1>
                                    <div className="d-flex justify-content-between mt-5">
                                        <div className="symbole"><svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 576 512" height="35" width="35"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z" /></svg>
                                        </div>
                                        <div className="count">
                                            <h3>{getDashboardData?.parentCount}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <div className="rounded rounded-3 shadow-lg m-2 p-3">
                                    <h1>Notice</h1>
                                    <div className="d-flex justify-content-between mt-5">
                                        <div className="symbole"><svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 512 512" height="35" width="35"><path d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z" /></svg>
                                        </div>
                                        <div className="count">
                                            <h3>{getDashboardData?.noticeCount}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <div className="rounded rounded-3 shadow-lg m-2 p-3">
                                    <h1>Syllabus</h1>
                                    <div className="d-flex justify-content-between mt-5">
                                        <div className="symbole"><svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg mx-1" viewBox="0 0 576 512" height="35" width="35"><path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" /></svg></div>
                                        <div className="count">
                                            <h3>{getDashboardData?.syllabusCount}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <div className="rounded rounded-3 shadow-lg m-2 p-3">
                                    <h1>Fee Structure </h1>
                                    <div className="d-flex justify-content-between mt-5">
                                        <div className="symbole"><svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 512 512" height="35" width="35"><path d="M64 256V160H224v96H64zm0 64H224v96H64V320zm224 96V320H448v96H288zM448 256H288V160H448v96zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" /></svg>
                                        </div>
                                        <div className="count">
                                            <h3>{getDashboardData?.feeStructuretCount}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-4">
                                <div className="rounded rounded-3 shadow-lg m-2 p-3">
                                    <h1>Student Fee</h1>
                                    <div className="d-flex justify-content-between mt-5">
                                        <div className="symbole"><svg xmlns="http://www.w3.org/2000/svg" className="sidebar-svg" viewBox="0 0 640 512" height="35" width="35"><path d="M96 96V320c0 35.3 28.7 64 64 64H576c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160c-35.3 0-64 28.7-64 64zm64 160c35.3 0 64 28.7 64 64H160V256zM224 96c0 35.3-28.7 64-64 64V96h64zM576 256v64H512c0-35.3 28.7-64 64-64zM512 96h64v64c-35.3 0-64-28.7-64-64zM288 208a80 80 0 1 1 160 0 80 80 0 1 1 -160 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V360c0 66.3 53.7 120 120 120H520c13.3 0 24-10.7 24-24s-10.7-24-24-24H120c-39.8 0-72-32.2-72-72V120z" /></svg>
                                        </div>
                                        <div className="count">
                                            <h3>{getDashboardData?.paymentCount}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
