import React, { useEffect, useMemo, useState } from 'react'
import { AdminLogin, DeleteTeacher, GetAllTeachersList, TeacherPasswordReset } from '../Features/properties/StudentService';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Components/Sidebar';
import Loader from '../Components/Loader';
import { CSVLink } from 'react-csv';
import CustomModal from '../Components/Modal';
import DeleteModal from '../Components/DeleteModal';
import { toast } from 'react-toastify';
import AddTeacher from '../Components/Teachers/AddTeacher';
import Cookies from "js-cookie";
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import { Form, Formik } from 'formik';
import ReactPaginate from 'react-paginate';

const headers = [
  { label: "Teacher Name", key: "teachername" },
  { label: "Gender", key: "gender" },
  { label: "Address", key: "address" },
  { label: "Mobile No", key: "mobileno" },
  { label: "Email", key: "email" },
  { label: "Bank Name", key: "bankname" },
  { label: "Account Number", key: "accountnumber" },
  { label: "Account Type", key: "accounttype" },
  { label: "Branch Name", key: "branchname" },
  { label: "Branch Address", key: "branchaddress" },
]

const validate = (values) => {
  const errors = {};

  if (!values.userid) {
    errors.userid = "Please enter user name";
  }

  if (!values.password) {
    errors.password = "Please enter password";
  }

  return errors;
};

const validate1 = (values) => {
  const errors = {};

  if(!values.password){
    errors.password = "This Field is Required";
  }

  if(!values.conpassword){
    errors.conpassword = "This Field is Required";
  }else if (!(values.conpassword === values.password)) {
    errors.conpassword = "Password and confirm password are not matched";
  }

  return errors;
}

const Teachers = () => {
  const [openModal, setOpenModal] = useState(false);
  const [isShowDeleteModal, setShowDeleteModal] = useState(false);
  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [isIsId, setIsId] = useState(0);
  const [deleteName, setDeleteName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);
  const parentsPerPage = 10;
  const { getAllTeachersList, loading } = useSelector((state) => state.students);

  useEffect(() => {
    const accessToken = Cookies.get("scholar-auth-token");
    if (!accessToken) {
      navigate("/adminpanel");
    }
  }, [navigate]);

  useEffect(() => {
    //   if (standard !== "" || medium !== "") {
    // dispatch(GetStudentFilterData({ standard: standard, medium: medium }));
    //   } else {
    dispatch(GetAllTeachersList());
    //   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVerifyModalClose = () => {
    setShowPassword(false);
    setOpenVerifyModal(false);
  };

  const handlePasswordModalClose = () => {
    setShowNewPassword(false);
    setShowConPassword(false);
    setOpenPasswordModal(false);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleAddFeeStructure = () => {
    setOpenModal(true);
  };

  const handleOnDelete = (e, id, teachername) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteName(teachername);
    setShowDeleteModal(true);
    setIsId(id);
  };

  const handleOnDeleteSave = async () => {
    const res = await dispatch(DeleteTeacher(isIsId));
    if (res?.type === "students/DeleteTeacher/fulfilled") {
      setShowDeleteModal(false);
      toast.success("Teacher Deleted Successfully", {
        autoClose: 3000,
      });
      setIsId(0);
      await dispatch(GetAllTeachersList());
    } else {
      toast.error("Something Went Wrong", {
        autoClose: 3000,
      });
    }
  };

  const columns = React.useMemo(
    () => [
      { Header: "No", accessor: (row, i) => i + 1 },
      { Header: 'Teacher Name', accessor: 'teachername' },
      { Header: 'Gender', accessor: 'gender' },
      { Header: 'DOB', accessor: 'dateofbirth' },
      { Header: 'Address', accessor: 'address' },
      { Header: 'Mobile No', accessor: 'mobileno' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Bank Name', accessor: 'bankdetails.bankname' },
      { Header: 'Account Number', accessor: 'bankdetails.accountnumber' },
      { Header: 'Account Type', accessor: 'bankdetails.accounttype' },
      { Header: 'Branch Name', accessor: 'bankdetails.branchname' },
      { Header: 'Branch Address', accessor: 'bankdetails.branchaddress' },
      { Header: 'IFSC Code', accessor: 'bankdetails.IFSCcode' },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <>
            <button
              className="btn btn-thmgreen mx-2"
              onClick={() => navigate(`/adminpanel/editTeachers?teachersid=${row.original._id}`)}
            >
              EDIT
            </button>
            <button
              className="btn btn-thmdanger"
              onClick={(e) => handleOnDelete(e, row.original._id, row.original.teachername)}
            >
              DELETE
            </button>
            <button
              className="btn btn-thm mx-2"
              onClick={() => {
                setOpenVerifyModal(true);
                setSelectedId(row.original._id);
              }}
            >
              Change Password
            </button>
          </>
        ),
      },
    ],
    [navigate]
  );

  const data = useMemo(() => getAllTeachersList, [getAllTeachersList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    state: { globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: pageNumber, pageSize: parentsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    gotoPage(selected);
  };

  const handleGlobalFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setPageNumber(0); // Reset page number when global filter changes
  };
  

  return (
    <>
      <Sidebar />
      <div className="home-section">
        {loading ? <Loader /> : <></>}
        <div className="container-fluid">
          <div className="row py-3 px-4">
            <div className="col-lg-12 mainpage-padding">
              <h1 className="mb-4">Teachers Detail</h1>
              <div className="text-end mb-3">
                <button
                  className="btn btn-thm add-btn"
                  onClick={() => handleAddFeeStructure()}
                >
                  + Add Teacher
                </button>
              </div>

                <div className="d-flex justify-content-end mb-2">
                {/* Search Input Field */}
                <input
                  type="search"
                  className="form-control filter-box"
                  placeholder="Search..."
                  value={globalFilter || ""}
                  onChange={handleGlobalFilterChange}
                />
              </div>

              <div className="table-responsive mb-5">
                <table
                  {...getTableProps()}
                  className="auto-index table text-center table-striped display scroll-tab table-margin"
                  style={{ width: "100%" }}
                >
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()} className="table-primary">
                        {headerGroup.headers.map(column => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} className="align-middle">
                          {row.cells.map(cell => (
                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {getAllTeachersList.length === 0 ? (
                  <h3 className="text-center text-secondary mt-5">
                    No Teacher found
                  </h3>
                ) : null}
                <div className="dt-export">
                  <CSVLink
                    data={getAllTeachersList}
                    headers={headers}
                    filename="Teacher_List.csv"
                  >
                    <button className="btn btn-thmgreen">
                      Export <span className="mx-1"></span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15px"
                        fill="#ffffff"
                        viewBox="0 0 384 512"
                      >
                        <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM155.7 250.2L192 302.1l36.3-51.9c7.6-10.9 22.6-13.5 33.4-5.9s13.5 22.6 5.9 33.4L221.3 344l46.4 66.2c7.6 10.9 5 25.8-5.9 33.4s-25.8 5-33.4-5.9L192 385.8l-36.3 51.9c-7.6 10.9-22.6 13.5-33.4 5.9s-13.5-22.6-5.9-33.4L162.7 344l-46.4-66.2c-7.6-10.9-5-25.8 5.9-33.4s25.8-5 33.4 5.9z" />
                      </svg>
                    </button>
                  </CSVLink>
                </div>
                <div className="dt-paging paging_full_numbers h-38px">
                  <ReactPaginate
                    previousLabel={<button className="btn btn-thm">{"<"}</button>}
                    nextLabel={<button className="btn btn-thm">{">"}</button>}
                    breakLabel={<button className="btn btn-thm">{"..."}</button>}
                    pageCount={Math.ceil(data.length / parentsPerPage)}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageLinkClassName={"page-link"}
                    renderOnZeroPageCount={null}
                    pageClassName={"page-item"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    breakClassName={"page-item"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        title={"Add Teacher"}
        show={openModal}
        onClose={handleModalClose}
        modalSize="modal-xl size-full"
      >
        <AddTeacher setOpenModal={setOpenModal} />
      </CustomModal>
      <DeleteModal
        isShowModal={isShowDeleteModal}
        title="Delete Teacher"
        handleOnDeleteSave={handleOnDeleteSave}
        setDeleteModal={setShowDeleteModal}
        deleteMsj={`Are you sure you want to delete ${deleteName} teacher?`}
      />
      <CustomModal
        title={"Verify Admin Details"}
        show={openVerifyModal}
        onClose={handleVerifyModalClose}
        modalSize="modal-md size-full"
      >
        <div className="mt-3">
          <Formik
            initialValues={{
              userid: "",
              password: "",
            }}
            validate={validate}
            enableReinitialize={true}
            onSubmit={async (values) => {
              let data = { ...values };
              const res = await dispatch(AdminLogin(data));
              if (res?.payload?.statuscode === 200) {
                handleVerifyModalClose();
                setOpenPasswordModal(true);
              } else {
                toast.error("Enter valid Username and Password to show Password !!", {
                  autoClose: 3000,
                });
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
            }) => (
              <Form>
                <div className="mb-4">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control login-inputbox shadow-none"
                      id="userid"
                      name="userid"
                      placeholder="Username"
                      onChange={handleChange}
                      value={values?.userid}
                    />
                    <span className="login-icons">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-person-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                      </svg>
                    </span>
                  </div>
                  {touched?.userid && errors?.userid ? (
                    <div className="error">{errors?.userid}</div>
                  ) : null}
                </div>
                <div className="mb-5">
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control login-inputbox shadow-none"
                      id="password"
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      value={values?.password}
                    />
                    <span className="login-icons">
                      {showPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-eye-fill"
                          viewBox="0 0 16 16"
                          onClick={() => setShowPassword(false)}
                        >
                          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-eye-slash-fill"
                          viewBox="0 0 16 16"
                          onClick={() => setShowPassword(true)}
                        >
                          <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                          <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                        </svg>
                      )}
                    </span>
                  </div>
                  {touched?.password && errors?.password ? (
                    <div className="error">{errors?.password}</div>
                  ) : null}
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-thm w-50 mt-3 login-btn mx-auto"
                  >
                    Verify
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </CustomModal>
      <CustomModal
        title={"Change Password"}
        show={openPasswordModal}
        onClose={handlePasswordModalClose}
        modalSize="modal-md size-full"
      >
        <div className="mt-3">
          <Formik
            initialValues={{
              password: "",
              conpassword: "",
            }}
            validate={validate1}
            enableReinitialize={true}
            onSubmit={async (values) => {
              let data = { 
                ...values,
                id: selectedId,
               };
               const res = await dispatch(TeacherPasswordReset(data));
               if (res?.type === "students/TeacherPasswordReset/fulfilled") {
                 toast.success("Password Changed Successfully", {
                   autoClose: 3000,
                 });
                 handlePasswordModalClose();
               } else if(res?.type === "students/TeacherPasswordReset/rejected") {
                 toast.error("Something went wrong !!", {
                   autoClose: 3000,
                 });
               }
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
            }) => (
              <Form>
                <div className="mb-4">
                  <div className="input-group">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      className="form-control login-inputbox shadow-none"
                      id="password"
                      name="password"
                      placeholder="Enter New Password"
                      onChange={handleChange}
                      value={values?.password}
                    />
                    <span className="login-icons">
                      {showNewPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-eye-slash-fill"
                          viewBox="0 0 16 16"
                          onClick={() => setShowNewPassword(false)}
                        >
                          <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                          <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-eye-fill"
                          viewBox="0 0 16 16"
                          onClick={() => setShowNewPassword(true)}
                        >
                          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />

                        </svg>
                      )}
                    </span>
                  </div>
                  {touched?.password && errors?.password ? (
                    <div className="error">{errors?.password}</div>
                  ) : null}
                </div>
                <div className="mb-5">
                  <div className="input-group">
                    <input
                      type={showConPassword ? "text" : "password"}
                      className="form-control login-inputbox shadow-none"
                      id="conpassword"
                      name="conpassword"
                      placeholder="Confirm Password"
                      onChange={handleChange}
                      value={values?.conpassword}
                    />
                    <span className="login-icons">
                      {showConPassword ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-eye-slash-fill"
                          viewBox="0 0 16 16"
                          onClick={() => setShowConPassword(false)}
                        >
                          <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                          <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-eye-fill"
                          viewBox="0 0 16 16"

                          onClick={() => setShowConPassword(true)}
                        >
                          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />

                        </svg>
                      )}
                    </span>
                  </div>
                  {touched?.conpassword && errors?.conpassword ? (
                    <div className="error">{errors?.conpassword}</div>
                  ) : null}
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-thm w-50 mt-3 login-btn mx-auto"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </CustomModal>
    </>
  )
}

export default Teachers
