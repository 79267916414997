import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { Form, Formik } from "formik";
import {
  AddNoticeRecord,
  GetAllNoticeList,
  GetSingleNoticeData,
  UpdateNotice,
} from "../../Features/properties/StudentService";
import { toast } from "react-toastify";

const AddNotice = ({ setOpenModal, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getSingleNoticeData, loading } = useSelector(
    (state) => state.students
  );

  useEffect(() => {
    if (id) {
      dispatch(GetSingleNoticeData(id));
    }
  }, [id, dispatch]);

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "This field is required";
    }
    if (!values.notice) {
      errors.notice = "This field is required";
    }
    if (!values.status) {
      errors.status = "This field is required";
    }
    
    return errors;
  };

  return (
    <>
      <div className="col-lg-12">
        {loading ? <Loader /> : <></>}
        <div className="my_dashboard_review">
          <div className="row mb20">
            <Formik
              initialValues={{
                title: id ? getSingleNoticeData?.title : "" || "",
                notice: id ? getSingleNoticeData?.notice : "" || "",
                status: id ? getSingleNoticeData?.status : true || "",
              }}
              validate={validate}
              enableReinitialize={true}
              onSubmit={async (values) => {
                let data = { ...values };
                let res;
                if (id) {
                  res = await dispatch(UpdateNotice({ id: id, data: data }));
                } else {
                  res = await dispatch(AddNoticeRecord(data));
                }

                if (res?.type === "students/AddNoticeRecord/fulfilled") {
                  toast.success("Notice Inserted Successfully", {
                    autoClose: 3000,
                  });
                  await dispatch(GetAllNoticeList());
                  setOpenModal(false);
                } else if (
                  res?.type === "students/UpdateNotice/fulfilled"
                ) {
                  toast.success("Notice Updated Successfully", {
                    autoClose: 3000,
                  });
                  await dispatch(GetAllNoticeList());
                  return navigate("/adminpanel/notice");
                } else {
                  toast.error("Something Went Wrong", {
                    autoClose: 3000,
                  });
                }
              }}
            >
              {({ values, errors, touched, setFieldValue, handleChange }) => (
                <Form>
                  <div className="row p-2">
                    <div className="form-outline col flex-fill">
                      <label className="form-label" htmlFor="title">
                        Title
                        <span className="error">*</span>
                      </label>
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        id="title"
                        onChange={handleChange}
                        value={values?.title}
                      />
                      {touched?.title && errors?.title ? (
                        <div className="error">{errors?.title}</div>
                      ) : null}
                    </div>
                    <div className="form-outline col flex-fill">
                      <label className="form-label" htmlFor="notice">
                        Notice
                        <span className="error">*</span>
                      </label>

                      <textarea
                        name="notice"
                        id="notice"
                        className="form-control"
                        cols="30"
                        rows="3"
                        onChange={handleChange}
                        value={values?.notice}
                      ></textarea>
                      {touched?.notice && errors?.notice ? (
                        <div className="error">{errors?.notice}</div>
                      ) : null}
                    </div>

                    <div data-mdb-input-init className="form-outline mb-4 col">
                      <label className="form-label" htmlFor="medium" id="status">
                        Select status
                        <span className="error">*</span>
                      </label>
                      <select
                        className="form-select"
                        placeholder="Select status"
                        name="status"
                        aria-label="Default select example"
                        onChange={handleChange}
                        value={values?.status}
                      >
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                      </select>
                      {touched?.status && errors?.status ? (
                        <div className="error">{errors?.status}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    {id ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-thmdanger mx-2"
                          onClick={() => navigate("/adminpanel/notice")}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-thmdanger mx-2"
                          onClick={() => setOpenModal(false)}
                        >
                          Cancel
                        </button>
                      </>
                    )}

                    {id ? (
                      <>
                        <input
                          type="submit"
                          id="addstudent"
                          name="submit"
                          value="Update Notice"
                          className="btn btn-thm"
                        />
                      </>
                    ) : (
                      <>
                        <input
                          type="submit"
                          id="addstudent"
                          name="submit"
                          value="Add Notice"
                          className="btn btn-thm"
                        />
                      </>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNotice;
