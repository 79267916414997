import React, { useState } from "react";
import selectedFiles from "../Utils/selectedFiles";

const svgData = `
  <svg xmlns="http://www.w3.org/2000/svg" width="10" viewBox="0 0 512 512" >
    <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"/>
  </svg>
`;

const base64SVG = `data:image/svg+xml;base64,${btoa(svgData)}`;

const SingleImageUploader = ({
  setSingleImages,
  singleSelectedImgs,
  editDataImage,
  extention,
}) => {
  const [error, setError] = useState("");

  const singleImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileSize = file.size / 1024 / 1024; // convert size to MB
      const fileType = file.type;

      if (fileSize > 3) {
        setError("File size should not exceed 3MB");
        return;
      } else if (fileType === "image/gif") {
        setError("GIF files are not allowed");
        return;
      } else {
        setError("");
      }

      // checking if the same file matches with the old stored array
      const isExist = singleSelectedImgs?.some(
        (file1) => file1?.file.name === file.name
      );

      if (!isExist) {
        setSingleImages(selectedFiles(e));
      } else {
        alert("You have selected this image already!");
      }
    }
  };

  return (
    <div className="portfolio_upload single_img_upload outline-image">
      {!extention && editDataImage && singleSelectedImgs?.length <= 0 && (
        <ul className="mb-0 h-100 imaage-upload-ul">
          <li className="list-inline-item h-100 w-100">
            <div className="portfolio_item mb0 h-100 w-100 cover">
              <img
                className="img-fluid cover"
                src={`https://shreescholarstarnursery.in/${editDataImage}`}
                alt="fp1.jpg"
                width="360"
                height="260"
                priority
              />
            </div>
          </li>
        </ul>
      )}
      {!extention && singleSelectedImgs?.length > 0 ? (
        singleSelectedImgs[0]?.file ? (
          <ul className="mb-0 h-100 imaage-upload-ul">
            <li className="list-inline-item h-100 w-100">
              <div className="portfolio_item mb0 h-100 w-100 cover">
                <img
                  className="img-fluid cover"
                  src={URL.createObjectURL(singleSelectedImgs[0]?.file)}
                  alt="Icon"
                  width="352"
                  height="252"
                  onError={(e) => {
                    e.target.onerror = null; // prevents looping
                    e.target.src = base64SVG;
                  }}
                />
              </div>
            </li>
          </ul>
        ) : undefined
      ) : undefined}
      <input
        type="file"
        onChange={singleImage}
        accept={extention ? ".png,.jpeg,.jpg,.pdf" : ".png,.jpeg,.jpg"}
        className="w-100"
      />
      {error && <div className="text-danger mt-2">{error}</div>}
      {!extention && !editDataImage && singleSelectedImgs?.length <= 0 ? (
        <>
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="40"
            >
              <path d="M256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM376.9 294.6L269.8 394.5c-3.8 3.5-8.7 5.5-13.8 5.5s-10.1-2-13.8-5.5L135.1 294.6c-4.5-4.2-7.1-10.1-7.1-16.3c0-12.3 10-22.3 22.3-22.3l57.7 0 0-96c0-17.7 14.3-32 32-32l32 0c17.7 0 32 14.3 32 32l0 96 57.7 0c12.3 0 22.3 10 22.3 22.3c0 6.2-2.6 12.1-7.1 16.3z" />
            </svg>
          </div>
          <p>
            <span className="fz14">Drag and drop images here</span>
          </p>
        </>
      ) : (
        ""
      )}

      {extention && !editDataImage && singleSelectedImgs?.length <= 0 ? (
        <>
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="40"
            >
              <path d="M256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM376.9 294.6L269.8 394.5c-3.8 3.5-8.7 5.5-13.8 5.5s-10.1-2-13.8-5.5L135.1 294.6c-4.5-4.2-7.1-10.1-7.1-16.3c0-12.3 10-22.3 22.3-22.3l57.7 0 0-96c0-17.7 14.3-32 32-32l32 0c17.7 0 32 14.3 32 32l0 96 57.7 0c12.3 0 22.3 10 22.3 22.3c0 6.2-2.6 12.1-7.1 16.3z" />
            </svg>
          </div>
          <p>
           
          <span className="fz14">Drag and drop pdf here</span>
          </p>
        </>
      ) : extention && singleSelectedImgs?.length > 0 ? (
        <>
          <div className="icon pdfextention">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="100">
                <path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"/>
              </svg>
            </span>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SingleImageUploader;
